import { RestService } from '@shared/services/rest.service';
import { ApiEndpoints } from '@app/config';
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
var AutomaticTariffService = /** @class */ (function () {
    function AutomaticTariffService(restService) {
        this.restService = restService;
    }
    AutomaticTariffService.prototype.getAutomaticTarrif = function (meterId) {
        return this.restService.get("" + ApiEndpoints.automaticTariff(meterId));
    };
    AutomaticTariffService.prototype.changeAutomaticTarrif = function (meterId, req) {
        return this.restService.put("" + ApiEndpoints.automaticTariff(meterId), req);
    };
    AutomaticTariffService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AutomaticTariffService_Factory() { return new AutomaticTariffService(i0.ɵɵinject(i1.RestService)); }, token: AutomaticTariffService, providedIn: "root" });
    return AutomaticTariffService;
}());
export { AutomaticTariffService };
