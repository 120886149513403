import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

// import { Observable } from 'rxjs';

import { Storage } from '@ionic/storage';
import { StorageKeys } from '@shared/models/enums';

@Injectable({
  providedIn: 'root',
})
export class Address2Guard implements CanActivate {
  constructor(private storage: Storage) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const uid = await this.storage.get(StorageKeys.UID);
    const address = await this.storage.get(StorageKeys.ADDRESS);

    if (uid && (address !== 'true' || address !== true)) {
      return true;
    }

    return false;
  }
}
