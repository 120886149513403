import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as components from './components';
import * as directives from './directives';
import * as pipes from './pipes';
import * as services from './services';

import { RestService } from '@shared/services/rest.service';

import { MeterComponent } from '@app/components/meter/meter.component';

const declarations = [
  components.SvgComponent,
  components.MpanNotFoundComponent,
  components.ModalComponent,
  components.WebsiteFooterComponent,
  components.WebsiteDownloadAppComponent,

  directives.BackDirective,
  directives.IonInputMaskDirective,
  directives.NoArrowDirective,
  directives.NumberInputDirective,
  directives.CustomNumberDecimalInputDirective,
  directives.SrollToElementDirective,
  directives.SupportsWebpDirective,
  directives.LazyImgDirective,

  pipes.SafeStylePipe,
  pipes.SafeHtmlPipe,
  pipes.SafeUrlPipe,
  pipes.ConvertValueToPositivePipe,
  pipes.Convert24To12FormatPipe,
  pipes.UnitSymbolPipe,
  pipes.UserDefaultCurrencyPipe,
];

@NgModule({
  declarations: [...declarations, MeterComponent],
  imports: [CommonModule],
  exports: [...declarations],
  providers: [
    services.ToastService,
    services.LoaderService,
    services.StorageService,
    services.LoggerService,
    services.RedirectionService,
    services.AutomaticTariffService,
    services.GeoPluginService,
    services.CurrencyExchangeRatesService,
    services.FileUploadService,

    RestService,
  ],
  entryComponents: [MeterComponent, components.ModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
