import { environment } from '@environments/environment';
const { apiUrl, awsUrl, stripe } = environment;

const baseUrlPhp = apiUrl;
const baseURL = awsUrl;
const stripeApiUrl = stripe.api;

export const ApiEndpoints = {
  budget: {
    minMaxValues: `${baseURL}min-max-values`,
    analysisData: (utilityType: number) =>
      `${baseURL}user/budget/${utilityType}`,
  },

  availableTarrif: `${baseURL}available-tariff`,
  automaticTariff: (meterId: number) => `${baseURL}automatic-tariff/${meterId}`,
  manualTariff: (meterId: number) => `${baseURL}manual-tariff/${meterId}`,
  defaultTariff: `${baseURL}default_rates`,

  stripe: {
    card: {
      add: `${baseURL}cards`,
      list: `${baseURL}cards`,
      delete: (cardId: string) => `${baseURL}cards/${cardId}`,
    },
    payment: {
      list: `${baseURL}payment`,
      send: `${baseURL}payment`,
    },

    api: {
      customers: {
        delete: (customerId: string) =>
          `${stripeApiUrl}/customers/${customerId}`,
      },
    },
  },

  consent: {
    success: (meterId: number) =>
      `${baseURL}consent/success?meter_id=${meterId}`,
    failure: (meterId: number) => `${baseURL}consent/fail?meter_id=${meterId}`,
  },

  guest: {
    order: `${baseURL}guest-order`,
    orderById: (id: number) => `${baseURL}guest-order/${id}`,
    transaction: `${baseURL}guest-transaction`,
  },
};
