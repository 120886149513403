import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';

// import { Observable } from 'rxjs';

import { Storage } from '@ionic/storage';
import { StorageKeys } from '@shared/models/enums';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard implements CanActivate {
  constructor(private router: Router, private storage: Storage) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const emailVerified = await this.storage.get(StorageKeys.EMAIL_VERIFIED);
    const uid = await this.storage.get(StorageKeys.UID);
    const address = await this.storage.get(StorageKeys.ADDRESS);

    if (emailVerified === 'false' || emailVerified === false) {
      this.router.navigate(['/verify-email']);
      return false;
    } else if (!uid) {
      return true;
    } else if (uid && (address === 'false' || address === false)) {
      this.router.navigate(['/signup/steps']);
      return false;
    } else {
      this.router.navigate(['/dashboard/account-overview']);
      return false;
    }
  }
}
