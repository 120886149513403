import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  HomeGuard,
  AddMeterGuard,
  AuthGuard,
  VerifyIhdMacGuard,
} from './guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  {
    path: 'dashboard',
    loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule',
  },
  {
    path: 'home',
    loadChildren: './pages/home/home.module#HomePageModule',
    canActivate: [HomeGuard],
  },
  {
    path: 'forgot',
    loadChildren: './pages/auth/forgot/forgot.module#ForgotPageModule',
    canActivate: [HomeGuard],
  },

  {
    path: 'login',
    loadChildren: './pages/auth/login/login.module#LoginPageModule',
    canActivate: [HomeGuard],
  },
  {
    path: 'verify-email',
    loadChildren:
      './pages/auth/verify-email/verify-email.module#VerifyEmailPageModule',
    canActivate: [AuthGuard],
  },

  // SignUp Routes
  {
    path: 'signup',
    loadChildren: './pages/auth/signup/signup.module#SignupPageModule',
  },
  // Step Info
  {
    path: 'signup/steps',
    loadChildren:
      './pages/auth/signup-steps/signup-steps.module#SignupStepsPageModule',
    canActivateChild: [AuthGuard],
  },
  // Step 1
  {
    path: 'signup/steps/select-address',
    loadChildren:
      './pages/auth/signup-select-address/signup-select-address.module#SignupSelectAddressPageModule',
    canActivateChild: [AuthGuard],
  },
  // Step 2
  {
    path: 'signup/steps/add-meter',
    canActivate: [AuthGuard, AddMeterGuard],
    loadChildren:
      './pages/auth/signup-add-meter/signup-add-meter.module#SignupAddMeterPageModule',
    // canActivateChild: [AuthGuard],
  },
  {
    path: 'signup/steps/unable-to-find',
    canActivate: [AuthGuard, AddMeterGuard],
    loadChildren:
      './pages/auth/signup-unable-to-find/signup-unable-to-find.module#SignupUnableToFindPageModule',
    // canActivateChild: [AuthGuard],
  },
  // Step 3
  {
    path: 'signup/steps/terms-and-conditions',
    canActivate: [AuthGuard, VerifyIhdMacGuard],
    loadChildren:
      './pages/auth/signup-terms-and-conditions/signup-terms-and-conditions.module#SignupTermsAndConditionsPageModule',
  },
  // Step 4
  {
    path: 'signup/steps/confirm-your-right',
    canActivate: [AuthGuard, VerifyIhdMacGuard],
    loadChildren:
      './pages/auth/signup-confirm-your-right/signup-confirm-your-right.module#SignupConfirmYourRightPageModule',
  },
  // Step 4 (Alternate)
  {
    path: 'signup/steps/verify-utility-bill',
    canActivate: [AuthGuard, VerifyIhdMacGuard],
    loadChildren:
      './pages/auth/signup-verify-utility-bill/signup-verify-utility-bill.module#SignupVerifyUtilityBillPageModule',
  },
  // Signup Success
  {
    path: 'signup/steps/success',
    loadChildren:
      './pages/auth/signup-success/signup-success.module#SignupSuccessPageModule',
    canActivate: [AuthGuard],
  },

  {
    path: '404',
    loadChildren: './pages/not-found/not-found.module#NotFoundPageModule',
  },

  // otherwise redirect to 404
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
