import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { ModalController, Platform } from '@ionic/angular';

import { MeterComponent } from '@app/components/meter/meter.component';

@Component({
  selector: 'app-mpan-not-found',
  templateUrl: './mpan-not-found.component.html',
  styleUrls: ['./mpan-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MpanNotFoundComponent implements OnInit {
  @Input() isVisible = false;
  @Output() close = new EventEmitter<boolean>();
  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {
    this.platform.backButton.subscribeWithPriority(1000, () => {
      this.hide();
    });
  }

  ngOnInit() {}

  hide() {
    this.isVisible = false;
    this.close.emit(this.isVisible);
  }

  notifyMe() {
    this.hide();
  }

  async openMpanModal() {
    const modal = await this.modalController.create({
      component: MeterComponent,
      cssClass: 'cst-modal-wrapper cst-modal-wrapper--mpan',
      componentProps: {
        pan: 'MPAN',
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }
}
