/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-connection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./no-connection.component";
var styles_NoConnectionComponent = [i0.styles];
var RenderType_NoConnectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoConnectionComponent, data: {} });
export { RenderType_NoConnectionComponent as RenderType_NoConnectionComponent };
export function View_NoConnectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-content", [["class", "ion-padding grey-bg main-content loginpage"], ["forceOverscroll", "false"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { forceOverscroll: [0, "forceOverscroll"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 7, "div", [["class", "no-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "hugo-move"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ion-img", [], null, null, null, i2.View_IonImg_0, i2.RenderType_IonImg)), i1.ɵdid(5, 49152, null, 0, i3.IonImg, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No internet connection"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are offline check your internet connection."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "false"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.image; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_NoConnectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-connection", [], null, null, null, View_NoConnectionComponent_0, RenderType_NoConnectionComponent)), i1.ɵdid(1, 114688, null, 0, i4.NoConnectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoConnectionComponentNgFactory = i1.ɵccf("app-no-connection", i4.NoConnectionComponent, View_NoConnectionComponent_Host_0, {}, {}, []);
export { NoConnectionComponentNgFactory as NoConnectionComponentNgFactory };
