import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumberInput]',
})
export class NumberInputDirective {
  @Input() allowOnly: string = 'number';
  @Input() allowDecimal: any = '';
  specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowUp',
    'ArrowDown',
  ];
  onlyNumberPattern = '^[0-9]*$';

  constructor(private el: ElementRef) {}

  // on enter key first validate then allow
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;

    var result = false;
    if (this.allowOnly == 'number') {
      result = this.AllowonlyNumber(e);
    } else if (this.allowOnly == 'WithDecimals') {
      result = this.AllowNumberWithDecimal(e);
    } else {
      result = this.AllowonlyNumber(e);
    }
  }

  // on paste info first validate  then allow
  @HostListener('paste', ['$event']) onPaste(e) {
    var result = false;
    let value = e.clipboardData.getData('text/plain');
    if (this.allowOnly == 'number') {
      this.matchRegularEx(e, this.onlyNumberPattern, value);
    } else if (this.allowOnly == 'WithDecimals') {
      let pattern = this.getPatternForDecimal();
      this.matchRegularEx(e, pattern, value);
    } else {
    }
  }

  AllowonlyNumber(e) {
    // allow special keys
    if (this.allowSpecialKey(e)) {
      return true;
    }
    this.matchRegularEx(e, this.onlyNumberPattern, e.key);
  }

  AllowNumberWithDecimal(e) {
    // allow special keys
    if (this.allowSpecialKey(e)) {
      return true;
    }

    let currentValue: string = this.el.nativeElement.value;
    let next: string = currentValue.concat(e.key);
    let pattern = this.getPatternForDecimal();
    this.matchRegularEx(e, pattern, next);
    return true;
  }

  getPatternForDecimal() {
    var pattern = /^\d*\.?\d{0,2}$/g;
    switch (this.allowDecimal) {
      case '1':
        pattern = /^\d*\.?\d{0,1}$/g;
        break;
      case '2':
        pattern = /^\d*\.?\d{0,2}$/g;
        break;
      case '3':
        pattern = /^\d*\.?\d{0,3}$/g;
        break;
      case '4':
        pattern = /^\d*\.?\d{0,4}$/g;
        break;
      case '5':
        pattern = /^\d*\.?\d{0,5}$/g;
        break;
      case '6':
        pattern = /^\d*\.?\d{0,6}$/g;
        break;
      case '7':
        pattern = /^\d*\.?\d{0,7}$/g;
        break;
      default:
        pattern = /^\d*\.?\d{0,2}$/g;
        break;
    }
    return pattern;
  }

  matchRegularEx(e, pattern, matchValue) {
    let regEx = new RegExp(pattern);
    let match = regEx.test(matchValue);
    if (!match) {
      e.preventDefault();
      return false;
    }
    return true;
  }
  allowSpecialKey(e) {
    if (
      this.specialKeys.indexOf(e.key) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode == 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (e.keyCode == 86 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode == 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return true;
    }
    return false;
  }
}
/* How To use:
 Demo input where you want to use
  Import this directive to your module and use like below
  1) <input type="text" appNumberInput >
  2) <input type="text" appNumberInput allowOnly='WithDecimals' allowDecimal='3'>

 */
