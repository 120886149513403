import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RestService } from '@shared/services/rest.service';
import { environment } from '@environments/environment';
import { ApiToken } from '@app/config';
import { ExchangeRate } from '../models/interfaces';

const { awsUrl } = environment;

@Injectable({
  providedIn: 'root',
})
export class CurrencyExchangeRatesService {
  constructor(private restService: RestService) {}

  fetchCurrencyExchangeRates(): Observable<ExchangeRate> {
    return this.restService
      .get(`${awsUrl}exchange-rates?token=${ApiToken}`)
      .pipe(
        tap((rates) => {
          for (let k in rates) {
            rates[k] = +rates[k].toFixed(2);
          }
          return rates;
        })
      );
  }
}
