import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import {
  AlertController,
  NavController,
  Platform,
  ActionSheetController,
  PopoverController,
  ToastController,
  ModalController,
  PickerController,
} from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { fromEvent, merge, of, Subscription } from 'rxjs';
import { mergeMap, map, filter } from 'rxjs/operators';

import { ApiService } from '@providers/api.service';
import {
  // StorageService,
  RedirectionService,
  // DataService,
  // GeoPluginService,
} from '@shared/services';
import { StorageKeys } from '@shared/models/enums';
import { DEFAULT_CURRENCY_USER } from './config';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  showHeader: boolean;
  showBackButton: boolean;
  defaultBack: boolean;

  household: string;
  disconnectSubscription: any;
  connectSubscription: any;

  isDeepLink = false;

  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  showNoNetworkContent = false;

  // showSplash = true;

  constructor(
    private platform: Platform,
    public router: Router,
    public titleService: Title,
    private activeRoute: ActivatedRoute,
    public apiProvider: ApiService,
    public navCtrl: NavController,
    public alertController: AlertController,

    public modalCtrl: ModalController,
    public actionSheetController: ActionSheetController,
    public popoverController: PopoverController,
    public toastController: ToastController,
    public pickerController: PickerController,
    private storage: Storage,

    // private storageService: StorageService,
    private redirectionService: RedirectionService // private dataService: DataService, // private geoPluginService: GeoPluginService
  ) {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
        if (this.networkStatus === false) {
          this.showNoNetworkContent = true;
        } else {
          // We just got a connection but we need to wait briefly
          // before we determine the connection type. Might need to wait.
          // prior to doing any api requests as well.
          setTimeout(() => {
            this.showNoNetworkContent = false;
          }, 3000);
        }
      });

    (async () => {
      this.household = await this.storage.get(StorageKeys.HOUSEHOLD_ID);
      // setting default tabpage to usage for fixing unable to go to root page from profile inner screens
      this.storage.set(StorageKeys.TAB_PAGE, 'dashboard/account-overview');
      // this.dataService.setStorageData({ [StorageKeys.TAB_PAGE]: 'co2' });

      // setting default currency to gbp
      const defaultCurrencyUser = localStorage.getItem('defaultCurrencyUser');
      if (!defaultCurrencyUser) {
        localStorage.setItem('defaultCurrencyUser', DEFAULT_CURRENCY_USER);
      }

      this.initializeApp();
      const uid = await this.storage.get(StorageKeys.UID);
      const address = await this.storage.get(StorageKeys.ADDRESS);
      if (uid && address != 'false') {
        this.apiProvider.getUserDetails().then(async (uD: any) => {
          const {
            meter: { meter_number, consent_status },
            verification_step,
            email_verified,
            is_signup_with_utility_bill,
          } = uD;

          if (consent_status === false) {
            if (verification_step == 4) {
              if (is_signup_with_utility_bill) {
                this.router.navigate(['/signup/steps/verify-utility-bill'], {
                  replaceUrl: true,
                  queryParams: {
                    screen: 'holding',
                  },
                });
              } else {
                this.router.navigate(['/signup/steps/add-meter'], {
                  replaceUrl: true,
                });
              }
            } else if (verification_step == null) {
              if (email_verified) {
                this.router.navigate(['/signup/steps'], {
                  replaceUrl: true,
                });
              } else {
                this.router.navigate(['/verify-email'], {
                  replaceUrl: true,
                });
              }
            } else if (verification_step <= 3) {
              this.redirectTo(verification_step, is_signup_with_utility_bill);
            } else {
              // passing 5 for redirecting to usage
              this.redirectTo(5);
            }
          }
          // else if (consent_status) {
          //   // passing 5 for redirecting to usage
          //   this.redirectTo(5);
          // }
          else {
            if (!this.isDeepLink) {
              const pageName = await this.storage.get(StorageKeys.REDIRECT_TO);

              // const queryParams = this.storage.set(StorageKeys.QUERY_PARAMS);
              if (pageName) {
                this.storage.remove(StorageKeys.REDIRECT_TO);
                this.storage.remove(StorageKeys.NOTIFICATION_ID);

                // this.storage.remove(StorageKeys.QUERY_PARAMS);
                this.redirectionService.redirectTo(pageName, '');
              } else {
                this.router
                  .navigate(['/dashboard/account-overview'], {
                    replaceUrl: true,
                  })
                  .then(() => {});
              }
            }
          }
        });
      }
    })();
  }

  ionViewWillLeave() {
    this.disconnectSubscription.unsubscribe();
    this.connectSubscription.unsubscribe();
  }
  ngOnDestroy(): void {
    if (this.networkStatus$) {
      this.networkStatus$.unsubscribe();
    }
  }

  async redirectTo(step: number, isSignupWithUtilityBill?: boolean) {
    const verificationMethod = await this.storage.get(
      StorageKeys.VERIFICATION_METHOD
    );
    const isMpanInvalid = await this.storage.get(StorageKeys.IS_MPAN_INVALID);

    switch (step) {
      case 1:
        this.router.navigate(['/signup/steps/add-meter']);
        break;
      case 2:
        // this.router.navigate(['/signup/steps/terms-and-conditions']);
        this.router.navigate([
          // verificationMethod == 'IHD'
          isMpanInvalid === true || isMpanInvalid === 'true'
            ? '/signup/steps/unable-to-find'
            : '/signup/steps/terms-and-conditions',
        ]);
        break;
      case 3:
        this.router.navigate(
          [
            isSignupWithUtilityBill
              ? '/signup/steps/verify-utility-bill'
              : '/signup/steps/confirm-your-right',
          ],
          {
            ...(isSignupWithUtilityBill && {
              queryParams: {
                screen: 'holding',
              },
            }),
          }
        );
        break;
      default:
        this.router
          .navigate(['/dashboard/account-overview'], { replaceUrl: true })
          .then(() => {});
        break;
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      window.addEventListener('load', () => {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j: any = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', environment.gtm.id);
        (function (id) {
          const s = document.createElement('script');
          s.async = true;
          s.src = 'https://www.googletagmanager.com/gtag/js?id=' + id;
          document.head.prepend(s);
        })(environment.gtag.id);
      });
      // setTimeout(() => (this.showSplash = false), 2500);
      // this.geoPluginService.geoLocation().subscribe({
      //   next: (res) => {
      //     this.dataService.setGeoLocationData(res);
      //   },
      //   error: (err) => {},
      // });
    });
  }

  async presentMaintenanceAlert(data: any) {
    const alert = await this.alertController.create({
      cssClass: 'cst-alert-dialog cst-alert-dialog--app-maintenance',
      header: data.title,
      message: data.message,
      backdropDismiss: false,
      mode: 'ios',
    });

    await alert.present();
  }

  ngOnInit() {
    this.subscribeToRouteChangeEvents();
  }

  private async setTitleFromRouteData(routeData) {
    const token = await this.storage.get(StorageKeys.AUTH_TOKEN);
    if (routeData) {
      const { showHeader, showBackButton, defaultBack } = routeData;
      this.showHeader = showHeader !== undefined ? showHeader : false;
      this.showBackButton =
        showBackButton !== undefined
          ? token
            ? showBackButton
            : this.router.url.includes('select-location') ||
              this.router.url.includes('co2/message')
            ? false
            : showBackButton
          : false;
      this.defaultBack = defaultBack !== undefined ? defaultBack : false;
    }
  }

  private getLatestChild(route) {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private subscribeToRouteChangeEvents() {
    // Set initial title
    const latestRoute = this.getLatestChild(this.activeRoute);
    if (latestRoute) {
      this.setTitleFromRouteData(latestRoute.data.getValue());
    }
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activeRoute),
        map((route) => this.getLatestChild(route)),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        // get and set default currency
        const defaultCurrencyUser = localStorage.getItem('defaultCurrencyUser');
        if (!defaultCurrencyUser) {
          localStorage.setItem('defaultCurrencyUser', DEFAULT_CURRENCY_USER);
        } else {
          localStorage.setItem('defaultCurrencyUser', defaultCurrencyUser);
        }
        this.setTitleFromRouteData(event);
      });
  }
}
