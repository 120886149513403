/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./svg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./svg.component";
var styles_SvgComponent = [i0.styles];
var RenderType_SvgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SvgComponent, data: {} });
export { RenderType_SvgComponent as RenderType_SvgComponent };
export function View_SvgComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [[":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["fill", "none"], ["style", "width: var(--icon-width); height: var(--icon-height)"], ["version", "1.1"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filePath; _ck(_v, 1, 0, currVal_0); }); }
export function View_SvgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["appSvg", ""]], null, null, null, View_SvgComponent_0, RenderType_SvgComponent)), i1.ɵdid(1, 114688, null, 0, i2.SvgComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SvgComponentNgFactory = i1.ɵccf("[appSvg]", i2.SvgComponent, View_SvgComponent_Host_0, { name: "name" }, {}, []);
export { SvgComponentNgFactory as SvgComponentNgFactory };
