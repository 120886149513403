import * as tslib_1 from "tslib";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { ToastController, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from '../environments/environment';
import { DataService, } from '@shared/services';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/angular";
import * as i3 from "@ionic/storage";
import * as i4 from "../app/shared/services/data.service";
var ApiService = /** @class */ (function () {
    function ApiService(http, toastController, loadingController, storage, dataService // private storageService: StorageService
    ) {
        this.http = http;
        this.toastController = toastController;
        this.loadingController = loadingController;
        this.storage = storage;
        this.dataService = dataService;
        this.apiUrl = environment.apiUrl;
        this.awsUrl = environment.awsUrl;
    }
    ApiService.prototype.show = function (msg, spinner) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.loading) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: msg ? msg : '',
                                spinner: spinner ? spinner : 'bubbles',
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        _b.label = 2;
                    case 2: return [4 /*yield*/, this.loading.present()];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApiService.prototype.hide = function () {
        if (this.loading) {
            this.loading.dismiss();
            this.loading = '';
        }
    };
    ApiService.prototype.appLoading = function (e, msg, spinner) {
        if (e == true) {
            this.show(msg, spinner);
        }
        if (e == false) {
            this.hide();
        }
    };
    ApiService.prototype.createToast = function (msg, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        try {
                            this.toast.dismiss();
                        }
                        catch (e) { }
                        _a = this;
                        return [4 /*yield*/, this.toastController.create({
                                message: msg,
                                duration: 2000,
                                color: type ? type : 'success',
                            })];
                    case 1:
                        _a.toast = _b.sent();
                        this.toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    // PHP APIs
    ApiService.prototype.saveContract = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var main1, _a, hid;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = {
                            mprn_mpan: data.mpan,
                            meter_serial_number: data.serial1,
                            mpan_top_line: data.top1,
                            utility_type_id: 1
                        };
                        return [4 /*yield*/, this.storage.get("uids" /* UID */)];
                    case 1:
                        main1 = (_a.hugo_user_id = _b.sent(),
                            _a.supplier_id = data.supplierElectricity,
                            _a);
                        return [4 /*yield*/, this.storage.get("household_id" /* HOUSEHOLD_ID */)];
                    case 2:
                        hid = _b.sent();
                        this.http
                            .post(this.apiUrl + 'meter/create', JSON.stringify(main1), {
                            params: new HttpParams().set('household_id', hid),
                        })
                            .subscribe(function (res) {
                            var obj = { res: res };
                            resolve({ res: res });
                            if (obj.res.id) {
                                _this.storage.set("meterid_electricity" /* METER_ID */, obj.res.id);
                            }
                        }, function (err) {
                            reject(err);
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    ApiService.prototype.updateContract = function (data, meterId, gasId) {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var hid, main1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("household_id" /* HOUSEHOLD_ID */)];
                    case 1:
                        hid = _a.sent();
                        main1 = {
                            mprn_mpan: data.mpan,
                            meter_serial_number: data.serial1,
                            mpan_top_line: data.top1,
                            utilty_type_id: 1,
                            supplier_id: data.supplierElectricity,
                            household_id: hid,
                        };
                        this.http
                            .post(this.awsUrl + 'meter/update?meter_id=' + meterId, JSON.stringify(main1))
                            .subscribe(function (ress) {
                            resolve({ res1: ress, res2: ress });
                        }, function (err) {
                            reject(err);
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    ApiService.prototype.consent = function (type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var meterId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("meterid_electricity" /* METER_ID */)];
                    case 1:
                        meterId = _a.sent();
                        if (meterId) {
                            return [2 /*return*/, this.http.get(this.awsUrl + "consent/" + type + "?meter_id=" + meterId, {
                                    responseType: 'text',
                                })];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ApiService.prototype.getSession = function (id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http
                .get(_this.awsUrl + 'consent/get-session-id?meter_id=' + id)
                .subscribe(function (data) {
                resolve(data);
            }, function (err) {
                reject(err);
            });
        });
    };
    ApiService.prototype.getMeters = function (id) {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, _b, _c;
            var _this = this;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _b = (_a = this.http).get;
                        _c = this.awsUrl +
                            'household/get-meters?id=';
                        return [4 /*yield*/, this.storage.get("household_id" /* HOUSEHOLD_ID */)];
                    case 1:
                        _b.apply(_a, [_c +
                                (_d.sent())])
                            .subscribe(function (data) {
                            data.map(function (value) {
                                if (value && value.utility_type_id === 1) {
                                    _this.storage.set("meterid_electricity" /* METER_ID */, value.id);
                                }
                            });
                            resolve(data);
                        }, function (err) {
                            reject(err);
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    ApiService.prototype.getAddress = function (id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(_this.awsUrl + 'household?id=' + id).subscribe(function (data) {
                if (data && data.postcode) {
                    _this.storage.set("postcode" /* POSTCODE */, data.postcode);
                }
                resolve(data);
            }, function (err) {
                reject(err);
            });
        });
    };
    ApiService.prototype.loadApi = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var postcode, householdId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("postcode" /* POSTCODE */)];
                    case 1:
                        postcode = _a.sent();
                        if (postcode) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.storage.get("household_id" /* HOUSEHOLD_ID */)];
                    case 2:
                        householdId = _a.sent();
                        this.getAddress(householdId).then(function (res) { });
                        return [2 /*return*/];
                }
            });
        });
    };
    ApiService.prototype.updateSuppliers = function (userId, supplierIdElec, supplierIdGas) {
        var _this = this;
        var obj = {
            user_id: userId,
            elec_supplier: supplierIdElec,
            gas_supplier: supplierIdGas,
        };
        return new Promise(function (resolve, reject) {
            _this.http
                .post(_this.awsUrl + 'meter/set-suppliers-by-user', JSON.stringify(obj), {})
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                //
                reject(err);
            });
        });
    };
    /* ========================================================== Python Apis ====================================================== */
    ApiService.prototype.getAddress2 = function (e) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(_this.awsUrl + 'address?postcode=' + e).subscribe(function (res) {
                resolve(res);
            }, function (err) {
                //
                reject(err);
            });
        });
    };
    ApiService.prototype.getSupplier = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var obj, supplier, _a, httpOptions;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        obj = {
                            type: e,
                        };
                        _a = this.awsUrl +
                            'suppliers?filter=' +
                            obj.type +
                            '&email=';
                        return [4 /*yield*/, this.storage.get("email" /* EMAIL */)];
                    case 1:
                        supplier = _a +
                            (_b.sent());
                        httpOptions = {
                            headers: new HttpHeaders({
                                'x-api-key': 'watfNqunXL3ssZq8xATRx81YKRMmbjll2QxE0Nmz',
                            }),
                        };
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                _this.http.get(supplier, httpOptions).subscribe(function (data) {
                                    resolve(data);
                                }, function (err) {
                                    reject(err);
                                });
                            })];
                }
            });
        });
    };
    ApiService.prototype.getCO2Consumption = function (consumptionBy, meterID, startDate, endDate, unit_type) {
        var _this = this;
        if (unit_type === void 0) { unit_type = 'consumption_units'; }
        // calls API when user requests consumption by day, month, hours
        // requires meterID, startDate and endDate in yyyy-mm-dd format
        // guarantees a promise with usage data
        return new Promise(function (resolve, reject) {
            _this.http
                .get(_this.awsUrl + 'co2?', {
                //headers: new HttpHeaders().set('Authorization', 'my-auth-token'),
                params: new HttpParams()
                    .set('meter_id', meterID)
                    // .set('user_id', await this.storage.get(StorageKeys.UID))
                    .set('date_from', startDate)
                    .set('date_to', endDate)
                    .set('unit_type', unit_type)
                    .set('type', consumptionBy),
            })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    // Consumption -->
    // <!-- User Profiles
    ApiService.prototype.getUserDetails = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(_this.awsUrl + 'profiles').subscribe(function (res) {
                _this.dataService.setUserDetails(res);
                if (res) {
                    if (res.meter) {
                        var meter = res.meter;
                        _this.storage.set("mpan" /* MPAN */, meter.meter_number);
                        _this.storage.set("contract" /* CONTRACT */, meter.consent_status);
                        _this.storage.set("postcode" /* POSTCODE */, meter.postcode);
                        if (meter.verification_method) {
                            _this.storage.set("verification_method" /* VERIFICATION_METHOD */, meter.verification_method);
                        }
                        else {
                            _this.storage.remove("verification_method" /* VERIFICATION_METHOD */);
                        }
                    }
                }
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    ApiService.prototype.updateUserDetails = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.put(_this.awsUrl + 'profiles', JSON.stringify(data)).subscribe(function (res) {
                resolve(res);
            }, function (err) {
                //
                reject(err);
            });
        });
    };
    //  -->
    // <!-- Security
    ApiService.prototype.signIn = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post(_this.awsUrl + 'sign-in', JSON.stringify(data)).subscribe(function (res) {
                resolve(res);
                return res;
            }, function (err) {
                reject(err);
            });
        });
    };
    ApiService.prototype.signUp = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post(_this.awsUrl + 'sign-up', JSON.stringify(data)).subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    ApiService.prototype.signOut = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // this.http.delete(this.awsUrl + 'sign-out?deviceId=' + deviceId).subscribe(
            _this.http.delete(_this.awsUrl + 'sign-out').subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    ApiService.prototype.updateAddress = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http
                .post(_this.awsUrl + 'update-meter-address', JSON.stringify(data))
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    ApiService.prototype.changePassword = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http
                .put(_this.awsUrl + 'change-password', JSON.stringify(data))
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                //
                reject(err);
            });
        });
    };
    ApiService.prototype.forgotPassword = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http
                .post(_this.awsUrl + 'forgot-password', JSON.stringify(data))
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    // Security -->
    // <!-- Email Verify
    ApiService.prototype.verifyEmail = function (token, email, req) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http
                .patch(_this.awsUrl + "verification?token=" + token + "&email=" + email, req)
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    ApiService.prototype.isEmailVerifed = function (token, email) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http
                .get(_this.awsUrl + "verification?token=" + token + "&email=" + email)
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    // Email Verify -->
    // <!-- Suggested Supplier
    ApiService.prototype.getSuggestedSuppliers = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _b = (_a = this.http).get;
                        _c = this.awsUrl +
                            'supplier_consumption?email=';
                        return [4 /*yield*/, this.storage.get("email" /* EMAIL */)];
                    case 1:
                        _b.apply(_a, [_c +
                                (_d.sent())])
                            .subscribe(function (res) {
                            resolve(res);
                        }, function (err) {
                            reject(err);
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    // Suggested Supplier -->
    ApiService.prototype.updateUserMeter = function (token, email, req) {
        return this.http
            .patch(this.awsUrl + "user-meter?token=" + token + "&email=" + email, req)
            .toPromise();
    };
    ApiService.prototype.saveCompletedSignupSteps = function (token, email, req) {
        return this.http
            .patch(this.awsUrl + "verification-flow?token=" + token + "&email=" + email, req)
            .toPromise();
    };
    ApiService.prototype.getVerifiedAddress = function (token, email) {
        return this.http
            .get(this.awsUrl + "address-verification?token=" + token + "&email=" + email)
            .toPromise();
    };
    ApiService.prototype.verifyAddress = function (token, email, req) {
        return this.http
            .patch(this.awsUrl + "address-verification?token=" + token + "&email=" + email, req)
            .toPromise();
    };
    // Single Utility
    ApiService.prototype.updateUtilityConnection = function (req) {
        return this.http.put(this.awsUrl + "update-utility-connection", req);
    };
    // Search Address
    ApiService.prototype.getAddressByPostcode = function (params) {
        return this.http.get(this.awsUrl + "search-address", {
            params: tslib_1.__assign({ customer_type: 'dom' }, params),
        });
    };
    ApiService.prototype.getMPANDetails = function (params) {
        return this.http.get(this.awsUrl + "get-mpan", { params: params });
    };
    // Find MPxN
    ApiService.prototype.findMpxn = function (mpxn) {
        return this.http.get(this.awsUrl + "find-mpxn?mprn_mpan=" + mpxn);
    };
    // Manual address
    ApiService.prototype.saveAddress = function (request) {
        return this.http.post(this.awsUrl + "add_manual_address", request);
    };
    // upload utility bill
    ApiService.prototype.uploadUtilityBill = function (request) {
        return this.http.post(this.awsUrl + "upload_bills", request);
    };
    ApiService.prototype.insertUtilityBill = function (request) {
        return this.http.post(this.awsUrl + "insert_uploaded_bills", request);
    };
    ApiService.prototype.updateBillConsent = function () {
        return this.http.put(this.awsUrl + "update-bill-consent", {});
    };
    // credit points
    ApiService.prototype.creditPoints = function (req) {
        return this.http.post(this.awsUrl + "credit-points", req);
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ToastController), i0.ɵɵinject(i2.LoadingController), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.DataService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
