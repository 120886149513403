import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AuthGuard, HomeGuard, Address2Guard } from './guard';

import { HeaderComponent } from '@app/components/header/header.component';
import { NoConnectionComponent } from '@app/components/no-connection/no-connection.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { InterceptorProvider } from '@providers/interceptor.service';
import { ErrorInterceptor } from '@providers/error-interceptor.service';
import { CancelHttpCallsService } from '@providers/cancel-http-calls.service';

import { DataService } from '@shared/services';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [AppComponent, HeaderComponent, NoConnectionComponent],
  imports: [
    BrowserModule,
    SharedModule,
    IonicModule.forRoot({ _forceStatusbarPadding: true }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [
    // Guards
    AuthGuard,
    HomeGuard,
    Address2Guard,

    // Services
    CancelHttpCallsService,
    DataService,

    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorProvider, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
