import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { ToastController, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { environment } from '../environments/environment';

import { StorageKeys } from '@shared/models/enums';
import {
  DataService,
  // StorageService
} from '@shared/services';
import { UtilityTypeString } from '@app/shared/models/enums';

interface Spinner {
  type:
    | 'bubbles'
    | 'circles'
    | 'circular'
    | 'crescent'
    | 'dots'
    | 'lines'
    | 'lines-small';
}

export interface UpdateUtilityConnectionInput {
  hasElectricityConnection?: boolean;
  hasGasConnection?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;
  awsUrl = environment.awsUrl;
  loading: any;
  toast: any;

  constructor(
    public http: HttpClient,
    public toastController: ToastController,
    public loadingController: LoadingController,
    private storage: Storage,
    private dataService: DataService // private storageService: StorageService
  ) {}

  async show(msg?: string, spinner?: Spinner['type']) {
    if (!this.loading) {
      this.loading = await this.loadingController.create({
        message: msg ? msg : '',
        spinner: spinner ? spinner : 'bubbles',
      });
    }
    await this.loading.present();
  }

  hide() {
    if (this.loading) {
      this.loading.dismiss();
      this.loading = '';
    }
  }

  appLoading(e: boolean, msg?: string, spinner?: Spinner['type']) {
    if (e == true) {
      this.show(msg, spinner);
    }
    if (e == false) {
      this.hide();
    }
  }

  async createToast(msg, type?) {
    try {
      this.toast.dismiss();
    } catch (e) {}

    this.toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: type ? type : 'success',
    });
    this.toast.present();
  }

  // PHP APIs

  saveContract(data) {
    return new Promise(async (resolve, reject) => {
      let main1 = {
        mprn_mpan: data.mpan,
        meter_serial_number: data.serial1,
        mpan_top_line: data.top1,
        utility_type_id: 1,
        hugo_user_id: await this.storage.get(StorageKeys.UID),
        supplier_id: data.supplierElectricity,
      };

      let hid = await this.storage.get(StorageKeys.HOUSEHOLD_ID);
      this.http
        .post(this.apiUrl + 'meter/create', JSON.stringify(main1), {
          params: new HttpParams().set('household_id', hid),
        })
        .subscribe(
          (res) => {
            let obj: any = { res: res };
            resolve({ res: res });
            if (obj.res.id) {
              this.storage.set(StorageKeys.METER_ID, obj.res.id);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateContract(data, meterId, gasId) {
    return new Promise(async (resolve, reject) => {
      let hid = await this.storage.get(StorageKeys.HOUSEHOLD_ID);
      let main1 = {
        mprn_mpan: data.mpan,
        meter_serial_number: data.serial1,
        mpan_top_line: data.top1,
        utilty_type_id: 1,
        supplier_id: data.supplierElectricity,
        household_id: hid,
      };

      this.http
        .post(
          this.awsUrl + 'meter/update?meter_id=' + meterId,
          JSON.stringify(main1)
        )
        .subscribe(
          (ress) => {
            resolve({ res1: ress, res2: ress });
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  async consent(type: 'success' | 'fail') {
    const meterId = await this.storage.get(StorageKeys.METER_ID);
    if (meterId) {
      return this.http.get(
        `${this.awsUrl}consent/${type}?meter_id=${meterId}`,
        {
          responseType: 'text',
        }
      );
    }
  }

  getSession(id) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.awsUrl + 'consent/get-session-id?meter_id=' + id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getMeters(id) {
    return new Promise(async (resolve, reject) => {
      this.http
        .get(
          this.awsUrl +
            'household/get-meters?id=' +
            (await this.storage.get(StorageKeys.HOUSEHOLD_ID))
        )
        .subscribe(
          (data: any) => {
            data.map((value) => {
              if (value && value.utility_type_id === 1) {
                this.storage.set(StorageKeys.METER_ID, value.id);
              }
            });
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  getAddress(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.awsUrl + 'household?id=' + id).subscribe(
        (data: any) => {
          if (data && data.postcode) {
            this.storage.set(StorageKeys.POSTCODE, data.postcode);
          }
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async loadApi() {
    const postcode = await this.storage.get(StorageKeys.POSTCODE);
    if (postcode) {
      return;
    }
    const householdId = await this.storage.get(StorageKeys.HOUSEHOLD_ID);
    this.getAddress(householdId).then((res: any) => {});
  }

  updateSuppliers(userId, supplierIdElec?, supplierIdGas?) {
    const obj = {
      user_id: userId,
      elec_supplier: supplierIdElec,
      gas_supplier: supplierIdGas,
    };
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.awsUrl + 'meter/set-suppliers-by-user',
          JSON.stringify(obj),
          {}
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            //

            reject(err);
          }
        );
    });
  }

  /* ========================================================== Python Apis ====================================================== */

  getAddress2(e) {
    return new Promise((resolve, reject) => {
      this.http.get(this.awsUrl + 'address?postcode=' + e).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          //

          reject(err);
        }
      );
    });
  }

  async getSupplier(e) {
    let obj: any = {
      type: e,
    };

    let supplier =
      this.awsUrl +
      'suppliers?filter=' +
      obj.type +
      '&email=' +
      (await this.storage.get(StorageKeys.EMAIL));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': 'watfNqunXL3ssZq8xATRx81YKRMmbjll2QxE0Nmz',
      }),
    };
    return new Promise((resolve, reject) => {
      this.http.get(supplier, httpOptions).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getCO2Consumption(
    consumptionBy,
    meterID,
    startDate,
    endDate,
    unit_type = 'consumption_units'
  ) {
    // calls API when user requests consumption by day, month, hours
    // requires meterID, startDate and endDate in yyyy-mm-dd format
    // guarantees a promise with usage data
    return new Promise((resolve, reject) => {
      this.http
        .get(this.awsUrl + 'co2?', {
          //headers: new HttpHeaders().set('Authorization', 'my-auth-token'),
          params: new HttpParams()
            .set('meter_id', meterID)
            // .set('user_id', await this.storage.get(StorageKeys.UID))
            .set('date_from', startDate)
            .set('date_to', endDate)
            .set('unit_type', unit_type)
            .set('type', consumptionBy),
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  // Consumption -->

  // <!-- User Profiles
  getUserDetails() {
    return new Promise((resolve, reject) => {
      this.http.get(this.awsUrl + 'profiles').subscribe(
        (res: any) => {
          this.dataService.setUserDetails(res);
          if (res) {
            if (res.meter) {
              const meter = res.meter;
              this.storage.set(StorageKeys.MPAN, meter.meter_number);
              this.storage.set(StorageKeys.CONTRACT, meter.consent_status);
              this.storage.set(StorageKeys.POSTCODE, meter.postcode);

              if (meter.verification_method) {
                this.storage.set(
                  StorageKeys.VERIFICATION_METHOD,
                  meter.verification_method
                );
              } else {
                this.storage.remove(StorageKeys.VERIFICATION_METHOD);
              }
            }
          }
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  updateUserDetails(data) {
    return new Promise((resolve, reject) => {
      this.http.put(this.awsUrl + 'profiles', JSON.stringify(data)).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          //

          reject(err);
        }
      );
    });
  }
  //  -->

  // <!-- Security
  signIn(data: any) {
    return new Promise((resolve, reject) => {
      this.http.post(this.awsUrl + 'sign-in', JSON.stringify(data)).subscribe(
        (res) => {
          resolve(res);
          return res;
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  signUp(data: any) {
    return new Promise((resolve, reject) => {
      this.http.post(this.awsUrl + 'sign-up', JSON.stringify(data)).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  signOut() {
    return new Promise((resolve, reject) => {
      // this.http.delete(this.awsUrl + 'sign-out?deviceId=' + deviceId).subscribe(
      this.http.delete(this.awsUrl + 'sign-out').subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateAddress(data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.awsUrl + 'update-meter-address', JSON.stringify(data))
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  changePassword(data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .put(this.awsUrl + 'change-password', JSON.stringify(data))
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            //

            reject(err);
          }
        );
    });
  }

  forgotPassword(data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.awsUrl + 'forgot-password', JSON.stringify(data))
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  // Security -->

  // <!-- Email Verify
  verifyEmail(
    token: string,
    email: string,
    req: { verification_code: string }
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .patch(`${this.awsUrl}verification?token=${token}&email=${email}`, req)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  isEmailVerifed(token: string, email: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.awsUrl}verification?token=${token}&email=${email}`)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  // Email Verify -->

  // <!-- Suggested Supplier
  getSuggestedSuppliers() {
    return new Promise(async (resolve, reject) => {
      this.http
        .get(
          this.awsUrl +
            'supplier_consumption?email=' +
            (await this.storage.get(StorageKeys.EMAIL))
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  // Suggested Supplier -->

  updateUserMeter(
    token: string,
    email: string,
    req: {
      movedInWithinThirteenMonths: boolean;
      movedInMonth?: number;
      movedInYear?: number;
    }
  ) {
    return this.http
      .patch(`${this.awsUrl}user-meter?token=${token}&email=${email}`, req)
      .toPromise();
  }

  saveCompletedSignupSteps(
    token: string,
    email: string,
    req: {
      verification_step: number;
      verification_method?: string;
    }
  ) {
    return this.http
      .patch(
        `${this.awsUrl}verification-flow?token=${token}&email=${email}`,
        req
      )
      .toPromise();
  }

  getVerifiedAddress(token: string, email: string) {
    return this.http
      .get(`${this.awsUrl}address-verification?token=${token}&email=${email}`)
      .toPromise();
  }
  verifyAddress(
    token: string,
    email: string,
    req: {
      isAddressVerified: boolean;
    }
  ) {
    return this.http
      .patch(
        `${this.awsUrl}address-verification?token=${token}&email=${email}`,
        req
      )
      .toPromise();
  }

  // Single Utility
  updateUtilityConnection(req: UpdateUtilityConnectionInput) {
    return this.http.put(`${this.awsUrl}update-utility-connection`, req);
  }

  // Search Address
  getAddressByPostcode(params: { postcode: string; customer_type?: string }) {
    return this.http.get(`${this.awsUrl}search-address`, {
      params: { customer_type: 'dom', ...params },
    });
  }
  getMPANDetails(params: { mpan: string }) {
    return this.http.get(`${this.awsUrl}get-mpan`, { params });
  }

  // Find MPxN
  findMpxn(mpxn: number) {
    return this.http.get(`${this.awsUrl}find-mpxn?mprn_mpan=${mpxn}`);
  }

  // Manual address
  saveAddress(request: {
    address1: string;
    address2?: string;
    city: string;
    county: string;
    postcode: string;
  }) {
    return this.http.post(`${this.awsUrl}add_manual_address`, request);
  }

  // upload utility bill
  uploadUtilityBill(request: any) {
    return this.http.post(`${this.awsUrl}upload_bills`, request);
  }
  insertUtilityBill(request: any) {
    return this.http.post(`${this.awsUrl}insert_uploaded_bills`, request);
  }
  updateBillConsent() {
    return this.http.put(`${this.awsUrl}update-bill-consent`, {});
  }

  // credit points
  creditPoints(req: { credits: number; stripe_response: any }) {
    return this.http.post(`${this.awsUrl}credit-points`, req);
  }
}
