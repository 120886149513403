import { Directive, Input, OnDestroy, OnInit } from '@angular/core';

import { IonInput } from '@ionic/angular';

import { Subject } from 'rxjs';

import { createTextMaskInputElement } from 'text-mask-core';

@Directive({
  selector: '[appIonInputMask]',
  providers: [IonInput],
})
export class IonInputMaskDirective implements OnInit, OnDestroy {
  @Input('appIonInputMask')
  private mask: Array<any> = [];
  private onDestroy: Subject<void> = new Subject<void>();

  constructor(public ionInput: IonInput) {}

  public ngOnInit() {
    this.configureInput();
  }

  public ngOnDestroy() {
    this.onDestroy.next();
  }

  public async configureInput() {
    const input = await this.ionInput.getInputElement();
    const maskedInput = createTextMaskInputElement({
      inputElement: input,
      mask: this.mask,
      guide: false,
    });
    // masking when event is not generated
    maskedInput.update(input.value);
    this.ionInput.value = input.value;

    // masking when event is  generated
    this.ionInput.ionChange.pipe().subscribe((event: CustomEvent) => {
      const { value } = event.detail;
      maskedInput.update(value);
      this.ionInput.value = input.value;
    });
  }
}
