import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

import { IconId } from './svg.type';

@Component({
  selector: '[appSvg]',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgComponent implements OnInit {
  @Input() name: IconId;
  constructor() {}

  ngOnInit() {}

  get filePath() {
    return `assets/svg/svg-definitions.svg#${this.name}`;
  }
}
