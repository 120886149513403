import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';

// import { Observable } from 'rxjs';

import { Storage } from '@ionic/storage';
import { StorageKeys } from '@shared/models/enums';

@Injectable({
  providedIn: 'root',
})
export class VerifyIhdMacGuard implements CanActivate {
  constructor(private router: Router, private storage: Storage) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const contract = await this.storage.get(StorageKeys.CONTRACT);
    if (contract === 'true' || contract === true) {
      this.router.navigate(['/dashboard/account-overview']);
      return false;
    }
    return true;
  }
}
