<ion-app>
  <!-- <div class="full-screen-loader" *ngIf="showSplash">
    <picture>
      <source srcset="assets/loader.webp" type="image/webp" />
      <img src="assets/loader.png" alt="loader" width="72" height="72" />
    </picture>
    <div class="loading-bar"></div>
  </div> -->

  <!-- [showHeader]="!showNoNetworkContent && showHeader" -->
  <app-header
    [showHeader]="!showNoNetworkContent"
    [showBackButton]="showBackButton"
    [defaultBack]="defaultBack"
  ></app-header>

  <ion-router-outlet [swipeGesture]="false"></ion-router-outlet>

  <app-no-connection [class.d-none]="!showNoNetworkContent"></app-no-connection>
</ion-app>
