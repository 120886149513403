<ion-content
  class="ion-padding grey-bg main-content loginpage"
  forceOverscroll="false"
>
  <div class="no-data">
    <div class="hugo-move">
      <ion-img [src]="image"></ion-img>
    </div>
    <h6>No internet connection</h6>
    <p>You are offline check your internet connection.</p>
  </div>
</ion-content>
