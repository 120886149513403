import * as tslib_1 from "tslib";
// import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
var Address2Guard = /** @class */ (function () {
    function Address2Guard(storage) {
        this.storage = storage;
    }
    Address2Guard.prototype.canActivate = function (next, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, address;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("uids" /* UID */)];
                    case 1:
                        uid = _a.sent();
                        return [4 /*yield*/, this.storage.get("address" /* ADDRESS */)];
                    case 2:
                        address = _a.sent();
                        if (uid && (address !== 'true' || address !== true)) {
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        });
    };
    Address2Guard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Address2Guard_Factory() { return new Address2Guard(i0.ɵɵinject(i1.Storage)); }, token: Address2Guard, providedIn: "root" });
    return Address2Guard;
}());
export { Address2Guard };
