import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(private http: HttpClient) {}

  setHeaders(headers: any) {
    const defaultHeaders = {
      'Content-Type': 'application/json',
    };
    const newHeaders = Object.assign(defaultHeaders, headers);
    const httpOptions = {
      headers: new HttpHeaders(newHeaders),
    };
    return httpOptions;
  }

  // private setParams(params: any) {
  //   const defaultParams = {};
  //   const newParams = Object.assign(defaultParams, params);
  //   const httpParams = {
  //     params: new HttpParams(newParams),
  //   };
  //   return httpParams;
  // }

  private setOptions(options: any) {
    const defaultOptions = {
      ...this.setHeaders(options && options.headers),
      // ...this.setParams(options && options.params),
      ...(options && options.params && { params: options.params }),
      ...(options && options.reportProgress && { reportProgress: true }),
      ...(options && options.observe && { observe: options.observe }),
      ...(options &&
        options.responseType && { responseType: options.responseType }),
      ...(options &&
        options.withCredentials && {
          withCredentials: options.withCredentials,
        }),
    };
    return defaultOptions;
  }

  // GET
  get(apiURL: string, options?: any): Observable<any> {
    return this.http
      .get(apiURL, { ...this.setOptions(options) })
      .pipe(catchError(this.errorHandl));
  }

  // POST
  post(apiURL: string, req: any, options?: any): Observable<any> {
    return this.http
      .post(apiURL, req, { ...this.setOptions(options) })
      .pipe(catchError(this.errorHandl));
  }

  // PUT
  put(apiURL: string, req: any, options?: any): Observable<any> {
    return this.http
      .put(apiURL, req, { ...this.setOptions(options) })
      .pipe(catchError(this.errorHandl));
  }

  // PATCH
  patch(apiURL: string, req: any, options?: any): Observable<any> {
    return this.http
      .patch(apiURL, req, { ...this.setOptions(options) })
      .pipe(catchError(this.errorHandl));
  }

  // DELETE
  delete(apiURL: string, options?: any): Observable<any> {
    return this.http
      .delete(apiURL, { ...this.setOptions(options) })
      .pipe(catchError(this.errorHandl));
  }

  // Error handling
  private errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = error;
    }
    return throwError(errorMessage);
  }
}
