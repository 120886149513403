import { tap } from 'rxjs/operators';
import { RestService } from '@shared/services/rest.service';
import { environment } from '@environments/environment';
import { ApiToken } from '@app/config';
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
var awsUrl = environment.awsUrl;
var CurrencyExchangeRatesService = /** @class */ (function () {
    function CurrencyExchangeRatesService(restService) {
        this.restService = restService;
    }
    CurrencyExchangeRatesService.prototype.fetchCurrencyExchangeRates = function () {
        return this.restService
            .get(awsUrl + "exchange-rates?token=" + ApiToken)
            .pipe(tap(function (rates) {
            for (var k in rates) {
                rates[k] = +rates[k].toFixed(2);
            }
            return rates;
        }));
    };
    CurrencyExchangeRatesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrencyExchangeRatesService_Factory() { return new CurrencyExchangeRatesService(i0.ɵɵinject(i1.RestService)); }, token: CurrencyExchangeRatesService, providedIn: "root" });
    return CurrencyExchangeRatesService;
}());
export { CurrencyExchangeRatesService };
