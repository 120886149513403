import * as tslib_1 from "tslib";
import { Router, } from '@angular/router';
// import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ionic/storage";
var HomeGuard = /** @class */ (function () {
    function HomeGuard(router, storage) {
        this.router = router;
        this.storage = storage;
    }
    HomeGuard.prototype.canActivate = function (next, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var emailVerified, uid, address;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("email_verified" /* EMAIL_VERIFIED */)];
                    case 1:
                        emailVerified = _a.sent();
                        return [4 /*yield*/, this.storage.get("uids" /* UID */)];
                    case 2:
                        uid = _a.sent();
                        return [4 /*yield*/, this.storage.get("address" /* ADDRESS */)];
                    case 3:
                        address = _a.sent();
                        if (emailVerified === 'false' || emailVerified === false) {
                            this.router.navigate(['/verify-email']);
                            return [2 /*return*/, false];
                        }
                        else if (!uid) {
                            return [2 /*return*/, true];
                        }
                        else if (uid && (address === 'false' || address === false)) {
                            this.router.navigate(['/signup/steps']);
                            return [2 /*return*/, false];
                        }
                        else {
                            this.router.navigate(['/dashboard/account-overview']);
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    HomeGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HomeGuard_Factory() { return new HomeGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Storage)); }, token: HomeGuard, providedIn: "root" });
    return HomeGuard;
}());
export { HomeGuard };
