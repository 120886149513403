import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

// import {
//   SecureStorage,
//   SecureStorageObject,
// } from '@ionic-native/secure-storage/ngx';
import { Storage } from '@ionic/storage';

import { ApiService } from './api.service';
import { CancelHttpCallsService } from '@providers/cancel-http-calls.service';
import { StorageKeys } from '@shared/models/enums';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  uid: string;

  constructor(
    public http: HttpClient,
    // public secureStorage: SecureStorage,
    private storage: Storage,
    public router: Router,

    public apiProvider: ApiService,
    private cancelHttpCallsService: CancelHttpCallsService
  ) {}

  setUid(uid) {
    return new Promise((resolve, reject) => {
      // sets user ID in local storage
      this.storage.set(StorageKeys.UID, uid.uid);
      this.storage.set(StorageKeys.UOBJ, JSON.stringify(uid));
      // this.storage.set('auth-token', uid.access_token);
      resolve('done');
    });
  }

  logout() {
    this.logoutWithoutRedirect();
    setTimeout(
      () => this.router.navigate(['/home'], { replaceUrl: true }),
      500
    );
  }

  logoutWithoutRedirect() {
    this.signOut();
    this.clearStorage();
  }

  signOut() {
    this.apiProvider
      .signOut()
      .then((res: any) => {
        this.apiProvider.createToast('Successfully Logout');
        this.cancelHttpCallsService.cancelPendingRequests();
      })
      .catch((err: any) => {});
  }

  async clearStorage() {
    const Email = await this.storage.get(StorageKeys.EMAIL);
    const TabPage = await this.storage.get(StorageKeys.TAB_PAGE);
    const UserDefaultCurrency = localStorage.getItem('defaultCurrencyUser');

    await this.storage.clear();
    if (Email) {
      this.storage.set(StorageKeys.EMAIL, Email);
    }
    this.storage.set(StorageKeys.TAB_PAGE, TabPage);
    localStorage.setItem('defaultCurrencyUser', UserDefaultCurrency);
  }
}
