import * as tslib_1 from "tslib";
import { Router, } from '@angular/router';
// import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ionic/storage";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, storage) {
        this.router = router;
        this.storage = storage;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("uids" /* UID */)];
                    case 1:
                        uid = _a.sent();
                        if (uid) {
                            // this.router.navigate(['/dashboard/account-overview']);
                            return [2 /*return*/, true];
                        }
                        else {
                            this.router.navigate(['/home']);
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthGuard.prototype.canActivateChild = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (state.url.split('/').includes('co2')) {
                            return [2 /*return*/, true];
                        }
                        return [4 /*yield*/, this.storage.get("uids" /* UID */)];
                    case 1:
                        uid = _a.sent();
                        if (!uid) {
                            this.router.navigate(['/home']);
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Storage)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
