export const environment = {
  production: true,

  baseUrl: 'https://tyj5o59nk5.execute-api.eu-west-2.amazonaws.com/production',

  apiUrl: 'https://idh6vx45il.execute-api.eu-west-2.amazonaws.com/prod/',
  awsUrl:
    // 'https://hmezmp3t4a.execute-api.eu-west-2.amazonaws.com/python/api/v1/', // old
    'https://tyj5o59nk5.execute-api.eu-west-2.amazonaws.com/production/api/v1/',

  shortenUrl: 'https://app.hugoenergyapp.co.uk/',

  websiteUrl: 'http://hugoenergyapp.co.uk',

  proxyUrl: 'https://consent-data-n3rgy.herokuapp.com',

  geoPlugin: 'http://www.geoplugin.net/json.gp',

  consent: {
    portalUrl: 'https://portal-consent.data.n3rgy.com/consent/',
    apiUrl: 'https://consent.data.n3rgy.com/consents/',
    id: 'ee0ab3e1-2b07-4d48-b119-15c7a2287b0c',
  },

  moretrees: {
    apiUrl: 'https://api.moretrees.eco/v1/basic/',
    public: {
      validationKey:
        'MT_$2a$10$ZAk0btRd0qrq8uvZElo9p.ayCDa4zuPvCtAdyfZiTFeDini8s/jki',
      apiKey: 'MT_$2a$10$ZAk0btRd0qrq8uvZElo9p.ncJTQXVM6EVdj2QjG5CiSgm.K7ye3xK',
      // validationKey:
      //   'MT_$2a$10$o6JTFSsXUqh7cBfxLhjwXelTMVG5RA8R.qqAvhOzTyr1TyQ89Bzmi',
      // apiKey: 'MT_$2a$10$o6JTFSsXUqh7cBfxLhjwXe6W8T8dOXiefWRn4GRddfJiy1/1ZlBvC',
    },
  },

  stripe: {
    api: 'https://api.stripe.com/v1',
    pk: 'pk_live_51JBG74BHrUi4vB5nDKWUPMKYnSkzDtXmZMIs0hVC0VmsXLulrAJgUvNgkp5NHYVdR51nKhGFlwV6oWVAQFTOpS8l00vhhjEvNO',
    sk: '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',
  },

  patch: {
    api: 'https://api.patch.io/v1',
    checkout: 'https://checkout.patch.io',
    ok: 'key_prod_ed71eba53c764ec266905aad41345310', // Organization Key
    pk: 'key_prod_1c0c35b5489420801df9f37a25629c40', // Personal Key
  },

  gtm: {
    id: 'GTM-TWG5NGH',
  },
  gtag: {
    id: 'UA-232218654-1',
  },
};
