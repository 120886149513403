import * as tslib_1 from "tslib";
import { RestService } from './rest.service';
import { ToastService } from './toast.service';
import { FileExtentions } from '../models/enums';
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "./toast.service";
var FileUploadService = /** @class */ (function () {
    function FileUploadService(restService, toastService) {
        this.restService = restService;
        this.toastService = toastService;
    }
    FileUploadService.prototype.setHeaders = function (options) {
        var headers = {
            'Content-Type': 'application/binary',
        };
        var newHeaders = Object.assign(headers, options);
        return { headers: newHeaders };
    };
    FileUploadService.prototype.uploadFileOnSignedUrl = function (url, file) {
        return this.restService.put("" + url, file, tslib_1.__assign({}, this.setHeaders({ 'Content-Type': file.type }), { reportProgress: true, observe: 'events' }));
    };
    // Utils
    FileUploadService.prototype.getExtension = function (fileName) {
        var parts = fileName.split('.');
        return parts[parts.length - 1].toLowerCase();
    };
    FileUploadService.prototype.validateFileExtension = function (fileName, supportedFileExtensions, toastMessage) {
        var extensions = supportedFileExtensions && supportedFileExtensions.length
            ? supportedFileExtensions
            : [
                FileExtentions.JPEG,
                FileExtentions.JPG,
                FileExtentions.PNG,
                FileExtentions.PDF,
            ];
        if (fileName && !extensions.includes(this.getExtension(fileName))) {
            this.toastService.presentToast(toastMessage ||
                "File type not supported please select ." + extensions.join(', .'), 'danger');
            return false;
        }
        return true;
    };
    FileUploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileUploadService_Factory() { return new FileUploadService(i0.ɵɵinject(i1.RestService), i0.ɵɵinject(i2.ToastService)); }, token: FileUploadService, providedIn: "root" });
    return FileUploadService;
}());
export { FileUploadService };
