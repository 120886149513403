import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild,
} from '@angular/router';

// import { Observable } from 'rxjs';

import { Storage } from '@ionic/storage';
import { StorageKeys } from '@shared/models/enums';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private storage: Storage) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const uid = await this.storage.get(StorageKeys.UID);
    if (uid) {
      // this.router.navigate(['/dashboard/account-overview']);
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (state.url.split('/').includes('co2')) {
      return true;
    }
    const uid = await this.storage.get(StorageKeys.UID);
    if (!uid) {
      this.router.navigate(['/home']);
      return false;
    }
    return true;
  }
}
