import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RestService } from './rest.service';
import { GeoLocation } from '../models/interfaces/output';
import { environment } from '@environments/environment';

const { baseUrl, geoPlugin, proxyUrl } = environment;

@Injectable({
  providedIn: 'root',
})
export class GeoPluginService {
  constructor(private restService: RestService) {}

  geoLocation(): Observable<GeoLocation> {
    // `${baseUrl}/geo-plugin`
    // return this.restService.get(`${geoPlugin}`);
    return this.restService.get(
      `${proxyUrl}/http://ip-api.com/json/?fields=countryCode`
    );
  }
}
