import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { Subject } from 'rxjs';
import { createTextMaskInputElement } from 'text-mask-core';
var IonInputMaskDirective = /** @class */ (function () {
    function IonInputMaskDirective(ionInput) {
        this.ionInput = ionInput;
        this.mask = [];
        this.onDestroy = new Subject();
    }
    IonInputMaskDirective.prototype.ngOnInit = function () {
        this.configureInput();
    };
    IonInputMaskDirective.prototype.ngOnDestroy = function () {
        this.onDestroy.next();
    };
    IonInputMaskDirective.prototype.configureInput = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var input, maskedInput;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.ionInput.getInputElement()];
                    case 1:
                        input = _a.sent();
                        maskedInput = createTextMaskInputElement({
                            inputElement: input,
                            mask: this.mask,
                            guide: false,
                        });
                        // masking when event is not generated
                        maskedInput.update(input.value);
                        this.ionInput.value = input.value;
                        // masking when event is  generated
                        this.ionInput.ionChange.pipe().subscribe(function (event) {
                            var value = event.detail.value;
                            maskedInput.update(value);
                            _this.ionInput.value = input.value;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return IonInputMaskDirective;
}());
export { IonInputMaskDirective };
