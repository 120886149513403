import { Injectable, isDevMode, enableProdMode } from '@angular/core';

export interface ILoggerService {
  log(message?: any, ...optionalParams: any[]): void;
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService implements ILoggerService {
  constructor() {}

  log(message?: any, ...optionalParams: any[]): void {
    // enableProdMode();
    if (isDevMode()) {
      console.log(message, ...optionalParams);
    }
  }
}
