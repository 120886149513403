import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { APP_STORE_URL, PLAY_STORE_URL } from '@app/config';

@Component({
  selector: 'app-website-download-app',
  templateUrl: './website-download-app.component.html',
  styleUrls: ['./website-download-app.component.scss'],
})
export class WebsiteDownloadAppComponent implements OnInit {
  appStoreUrl = APP_STORE_URL;
  playStoreUrl = PLAY_STORE_URL;

  sectionId: string;

  constructor(router: Router) {
    let url: any = router.url;
    if (url.indexOf('#') >= 0) {
      url = url.split('#')[0];
    }
    url = url.split('/');
    url.splice(0, 1);
    this.sectionId = `${url.join('-')}-get-hugo-section`;
  }

  ngOnInit() {}
}
