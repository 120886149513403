import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import supportsWebP from 'supports-webp';
var SupportsWebpDirective = /** @class */ (function () {
    function SupportsWebpDirective(el) {
        var _this = this;
        this.el = el;
        setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.el.nativeElement.classList).add;
                        return [4 /*yield*/, supportsWebP];
                    case 1:
                        _b.apply(_a, [(_c.sent()) ? 'webp' : 'no-webp']);
                        return [2 /*return*/];
                }
            });
        }); }, 0);
    }
    return SupportsWebpDirective;
}());
export { SupportsWebpDirective };
