import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  // HttpResponse,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { Storage } from '@ionic/storage';

import { Observable, from } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { StorageKeys } from '@shared/models/enums';
import { StorageService } from '@shared/services';
import { CancelHttpCallsService } from './cancel-http-calls.service';

import { environment } from '@environments/environment';

const { apiUrl, awsUrl, stripe, patch, moretrees } = environment;
@Injectable()
export class InterceptorProvider implements HttpInterceptor {
  constructor(
    private storage: Storage,

    private storageService: StorageService,
    private cancelHttpCallsService: CancelHttpCallsService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.storage.get(StorageKeys.AUTH_TOKEN)).pipe(
      switchMap((token) => {
        const isUrlStartsWith = (s: string, p?: number) =>
          request.url.startsWith(s, p);

        if (isUrlStartsWith(apiUrl) || isUrlStartsWith(awsUrl)) {
          if (!request.headers.has('Authorization') && token) {
            request = request.clone({
              headers: request.headers.set('Authorization', 'Bearer ' + token),
            });
          }
        }

        if (isUrlStartsWith(stripe.api)) {
          // if (!request.headers.has('Authorization') && token) {
          if (!request.headers.has('Authorization')) {
            request = request.clone({
              headers: request.headers.set(
                'Authorization',
                'Bearer ' + stripe.sk
              ),
            });
          }
        }

        if (isUrlStartsWith(moretrees.apiUrl)) {
          if (!request.headers.has('Authorization') && token) {
            request = request.clone({
              headers: request.headers.set(
                'Authorization',
                moretrees.public.validationKey
              ),
            });
          }
        }

        if (isUrlStartsWith(patch.api)) {
          // if (!request.headers.has('Authorization') && token) {
          if (!request.headers.has('Authorization')) {
            request = request.clone({
              headers: request.headers
                .set('Authorization', 'Bearer ' + patch.ok)
                .set('Accept', 'application/json'),
            });
          }
        }

        if (!request.headers.has('Content-Type')) {
          request = request.clone({
            headers: request.headers.set('Content-Type', 'application/json'),
          });
        }

        // if (this.debug) {
        //     request = request.clone({ url: this.url + request.url + '?XDEBUG_SESSION_START=1'});
        // }

        return next.handle(request).pipe(
          takeUntil(this.cancelHttpCallsService.onCancelPendingRequests()),
          map((event: HttpEvent<any>) => {
            // if (event instanceof HttpResponse) {
            //   // do nothing for now
            // }
            return event;
          })
        );
      })
    );
  }
}
