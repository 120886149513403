<div class="mpan-modal" id="mpan-modal">
  <div class="mpan-modal__inner">
    <div class="mpan-modal__header">
      <h3 class="mpan-modal__header-title">MPAN Number</h3>
      <ion-button
        fill="clear"
        class="mpan-modal__header-close"
        (click)="close()"
      >
        <span appSvg name="close"></span>
      </ion-button>
    </div>
    <div class="mpan-modal__body">
      <div class="mpan-modal__body-img">
        <img
          src="/assets/svg/mpan-number.svg"
          width="382"
          height="113"
          alt=""
        />
      </div>
      <div class="mpan-modal__body-content">
        <p class="mpan-modal__body-para">
          Your MPAN is on your bill. If you’re looking on a bill, it’s the 13
          digit number in the box marked as ‘Supply Number’ on the second page,
          to the right hand side. It is the bottom row of numbers.
        </p>
        <p class="mpan-modal__body-para">
          They’re often printed at the bottom of the first page of your bill or
          on the reverse of the first page.
        </p>
      </div>
    </div>
  </div>
</div>
