import { OnInit, } from '@angular/core';
var SvgComponent = /** @class */ (function () {
    function SvgComponent() {
    }
    SvgComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(SvgComponent.prototype, "filePath", {
        get: function () {
            return "assets/svg/svg-definitions.svg#" + this.name;
        },
        enumerable: true,
        configurable: true
    });
    return SvgComponent;
}());
export { SvgComponent };
