import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { MeterComponent } from '@app/components/meter/meter.component';
var MpanNotFoundComponent = /** @class */ (function () {
    function MpanNotFoundComponent(modalController, platform) {
        var _this = this;
        this.modalController = modalController;
        this.platform = platform;
        this.isVisible = false;
        this.close = new EventEmitter();
        this.platform.backButton.subscribeWithPriority(1000, function () {
            _this.hide();
        });
    }
    MpanNotFoundComponent.prototype.ngOnInit = function () { };
    MpanNotFoundComponent.prototype.hide = function () {
        this.isVisible = false;
        this.close.emit(this.isVisible);
    };
    MpanNotFoundComponent.prototype.notifyMe = function () {
        this.hide();
    };
    MpanNotFoundComponent.prototype.openMpanModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: MeterComponent,
                            cssClass: 'cst-modal-wrapper cst-modal-wrapper--mpan',
                            componentProps: {
                                pan: 'MPAN',
                            },
                            backdropDismiss: false,
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return MpanNotFoundComponent;
}());
export { MpanNotFoundComponent };
