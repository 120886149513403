import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retryWhen, tap, delay } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { ToastService } from '@shared/services';
import { CancelHttpCallsService } from './cancel-http-calls.service';

import { environment } from '@environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  isSessionExpired = 0;
  retryAttemps = 0;

  constructor(
    public router: Router,
    private authService: AuthService,
    private toastService: ToastService,
    private cancelHttpCallsService: CancelHttpCallsService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // Retry Logic
      retryWhen((errors) =>
        errors.pipe(
          delay(500),
          tap((error) => {
            // making request 2 more times if getting 500
            if (error.status >= 500 && this.retryAttemps <= 1) {
              this.retryAttemps++;
            } else {
              this.retryAttemps = 0;
              throw error;
            }
          })
        )
      ),
      catchError((error: HttpErrorResponse) => {
        const status = error.status;

        if (status >= 500) {
          this.toastService.presentToast(
            'Internal server error. Please try again',
            'danger'
          );
        }

        if (status >= 400 && status < 500) {
          if (error.error && error.error.message && !error.error.logout) {
            this.toastService.presentToast(error.error.message, 'danger');
          }
        }

        const isAwsUrl = request.url.startsWith(environment.awsUrl);
        if (isAwsUrl && [401, 403].indexOf(error.status) !== -1) {
          this.isSessionExpired++;
          if (this.isSessionExpired === 1) {
            // Cancles all pending http calls
            this.cancelHttpCallsService.cancelPendingRequests();
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            this.authService.clearStorage();

            this.router
              .navigate(['/home'], {
                replaceUrl: true,
                // queryParams: { redirectUrl: this.router.url },
              })
              .then((v) => {
                this.toastService.presentToast(
                  error.error.message || 'Session expired please login',
                  'danger'
                );
                // prevent from api loop calls
                setTimeout(() => {
                  this.isSessionExpired = 0;
                }, 1500);
              });
          }
        }
        return throwError(error);
      })
    );
  }
}
