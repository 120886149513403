import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// enum PageNames {
//   BUDGET = 'Budget',
// }

const PageNames = {
  BUDGET: 'Budget',
};

@Injectable({
  providedIn: 'root',
})
export class RedirectionService {
  constructor(public router: Router) {}

  redirectTo(pageName: string, queryParams: any) {
    switch (pageName) {
      case PageNames.BUDGET:
        this.navigateTo('/dashboard/budget', queryParams);
        break;
      default:
        this.navigateTo('/dashboard/account-overview', queryParams);
    }
  }

  navigateTo(path: string, queryParams: any) {
    queryParams
      ? this.router.navigate([path], { queryParams })
      : this.router.navigate([path]);
  }
}
