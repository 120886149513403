/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./meter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/components/svg/svg.component.ngfactory";
import * as i3 from "../../shared/components/svg/svg.component";
import * as i4 from "../../shared/directives/lazy-img.directive";
import * as i5 from "./meter.component";
import * as i6 from "@ionic/angular";
var styles_MeterComponent = [i0.styles];
var RenderType_MeterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MeterComponent, data: {} });
export { RenderType_MeterComponent as RenderType_MeterComponent };
export function View_MeterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "mpan-modal"], ["id", "mpan-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "mpan-modal__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "mpan-modal__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "mpan-modal__header-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["MPAN Number"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ion-button", [["class", "mpan-modal__header-close"], ["fill", "clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["appSvg", ""], ["name", "close"]], null, null, null, i2.View_SvgComponent_0, i2.RenderType_SvgComponent)), i1.ɵdid(7, 114688, null, 0, i3.SvgComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "mpan-modal__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "mpan-modal__body-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "img", [["alt", ""], ["height", "113"], ["src", "/assets/svg/mpan-number.svg"], ["width", "382"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i4.LazyImgDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "mpan-modal__body-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "mpan-modal__body-para"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your MPAN is on your bill. If you\u2019re looking on a bill, it\u2019s the 13 digit number in the box marked as \u2018Supply Number\u2019 on the second page, to the right hand side. It is the bottom row of numbers. "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [["class", "mpan-modal__body-para"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" They\u2019re often printed at the bottom of the first page of your bill or on the reverse of the first page. "]))], function (_ck, _v) { var currVal_0 = "close"; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_MeterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-meter", [], null, null, null, View_MeterComponent_0, RenderType_MeterComponent)), i1.ɵdid(1, 2211840, null, 0, i5.MeterComponent, [i6.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MeterComponentNgFactory = i1.ɵccf("app-meter", i5.MeterComponent, View_MeterComponent_Host_0, { pan: "pan" }, {}, []);
export { MeterComponentNgFactory as MeterComponentNgFactory };
