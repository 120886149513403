import * as tslib_1 from "tslib";
import { OnDestroy, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { DEFAULT_CURRENCY_PAYMENT } from '@app/config';
import { DataService } from '../services';
var UserDefaultCurrencyPipe = /** @class */ (function (_super) {
    tslib_1.__extends(UserDefaultCurrencyPipe, _super);
    function UserDefaultCurrencyPipe(dataService) {
        var _this = _super.call(this, 'en-US') || this;
        _this.dataService = dataService;
        _this.currencyExchangeRates$ = _this.dataService
            .getCurrencyExchangeRates()
            .subscribe(function (rates) {
            _this.rates = rates;
        });
        return _this;
    }
    UserDefaultCurrencyPipe.prototype.transform = function (value, currencyCode) {
        var USER_DEFAULT_CURRENCY = localStorage.getItem('defaultCurrencyUser');
        if (!this.rates || (this.rates && !this.rates[USER_DEFAULT_CURRENCY])) {
            return value;
        }
        var rate = parseFloat((this.rates[USER_DEFAULT_CURRENCY] / this.rates[DEFAULT_CURRENCY_PAYMENT]).toFixed(2));
        return _super.prototype.transform.call(this, value ? value * rate : 0, currencyCode
            ? currencyCode.toUpperCase()
            : USER_DEFAULT_CURRENCY.toUpperCase(), 'symbol');
    };
    UserDefaultCurrencyPipe.prototype.ngOnDestroy = function () {
        if (this.currencyExchangeRates$) {
            this.currencyExchangeRates$.unsubscribe();
        }
    };
    return UserDefaultCurrencyPipe;
}(CurrencyPipe));
export { UserDefaultCurrencyPipe };
