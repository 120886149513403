import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CancelHttpCallsService {
  private cancelPendingRequests$ = new Subject<void>();

  constructor() {}

  /* Cancels all pending Http requests. */
  cancelPendingRequests() {
    // This aborts all HTTP requests.
    this.cancelPendingRequests$.next();
    // This completes the subject properlly.
    // this.cancelPendingRequests$.complete();
  }

  onCancelPendingRequests() {
    return this.cancelPendingRequests$.asObservable();
  }
}
