import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, AfterContentChecked {
  @Input() title: string;
  @Input() additionalInformation: string;
  @Input() content: string;

  constructor(public modalController: ModalController) {}

  ngOnInit() {}

  ngAfterContentChecked() {
    const el = document.getElementById('help-content-modal');
    // el.parentElement.parentElement.parentElement.style.setProperty(
    //   '--height',
    //   `${el.offsetHeight}px`
    // );
    el.parentElement.parentElement.parentElement.setAttribute(
      'style',
      `--height: ${el.offsetHeight}px !important; z-index: 20000 !important;`
    );
  }

  close() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
