<div class="help-content-modal" id="help-content-modal">
  <div class="help-content-modal__inner">
    <div class="help-content-modal__header">
      <h3 class="help-content-modal__header-title">{{ title }}</h3>
      <ion-button
        fill="clear"
        class="help-content-modal__header-close"
        (click)="close()"
      >
        <span appSvg name="close"></span>
      </ion-button>
    </div>
    <div class="help-content-modal__body">
      <div class="help-content-modal__body-content">
        <ng-container *ngIf="additionalInformation">
          <p
            class="help-content-modal__body-para"
            [innerHTML]="additionalInformation"
          ></p>
        </ng-container>
        <ng-container *ngIf="content">
          <p class="help-content-modal__body-para" [innerHTML]="content"></p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
