import * as tslib_1 from "tslib";
import { ToastController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
var ToastService = /** @class */ (function () {
    function ToastService(toastController) {
        this.toastController = toastController;
    }
    /**
     * Present toast alert.
     * @param message - Toastr message.
     * @param color - Toastr type, default is success.
     */
    ToastService.prototype.presentToast = function (message, color) {
        if (color === void 0) { color = 'success'; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: message,
                            duration: 3500,
                            color: color,
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(i0.ɵɵinject(i1.ToastController)); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
