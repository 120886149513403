import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, OnChanges, } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { DataService } from '@shared/services';
import { AuthService } from '@providers/auth.service';
import { APP_STORE_URL, PLAY_STORE_URL } from '@app/config';
import { environment } from '@environments/environment';
var DOMAIN = environment.websiteUrl;
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, navCtrl, cdr, storage, dataService, authService) {
        this.router = router;
        this.navCtrl = navCtrl;
        this.cdr = cdr;
        this.storage = storage;
        this.dataService = dataService;
        this.authService = authService;
        this._showHeader = false;
        this._showBackButton = false;
        this._defaultBack = false;
        this.consentStatus = false;
        this.menus = [
            {
                label: 'Features',
                url: DOMAIN + "/#features",
                type: 'web-target',
                isVisible: true,
            },
            {
                label: 'Media',
                type: 'dropdown',
                isVisible: true,
                subMenus: [
                    {
                        label: 'Press',
                        url: DOMAIN + "/topics/press/",
                        type: 'web-link',
                        isVisible: true,
                    },
                    {
                        label: 'Blog',
                        url: DOMAIN + "/topics/blog/",
                        type: 'web-link',
                        isVisible: true,
                    },
                    {
                        label: 'Videos',
                        url: DOMAIN + "/topics/meet-hugo/",
                        type: 'web-link',
                        isVisible: true,
                    },
                ],
            },
            {
                label: 'Ask Hugo',
                type: 'dropdown',
                isVisible: true,
                subMenus: [
                    {
                        label: 'Virtual Assistant',
                        url: "" + DOMAIN,
                        type: 'web-link',
                        isVisible: true,
                    },
                    {
                        label: 'FAQs',
                        url: DOMAIN + "/faqs/",
                        type: 'web-link',
                        isVisible: true,
                    },
                    {
                        label: 'Contact Us',
                        url: DOMAIN + "/contact-us/",
                        type: 'web-link',
                        isVisible: true,
                    },
                ],
            },
            {
                label: 'Offset Co2',
                url: "/dashboard/co2/select-location",
                type: 'app-link',
                isVisible: true,
            },
        ];
        this.domain = DOMAIN + "/";
        this.isLoggedIn = false;
        this.isVisible = {
            getHugo: true,
            mobileMenu: false,
        };
        this.appStoreUrl = APP_STORE_URL;
        this.playStoreUrl = PLAY_STORE_URL;
    }
    Object.defineProperty(HeaderComponent.prototype, "showHeader", {
        get: function () {
            return this._showHeader;
        },
        set: function (showHeader) {
            this._showHeader = showHeader;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "showBackButton", {
        get: function () {
            return this._showBackButton;
        },
        set: function (showBackButton) {
            this._showBackButton = showBackButton;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "defaultBack", {
        set: function (value) {
            this._defaultBack = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "defaultback", {
        get: function () {
            return this._defaultBack;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataService.getConsentStatus().subscribe(function (status) {
            _this.consentStatus = status;
        });
        this.router.events.subscribe(function (e) {
            if (e instanceof NavigationEnd) {
                _this.url = e.url;
                var url = e.url;
                if (url.indexOf('#') >= 0) {
                    url = url.split('#')[0];
                }
                url = url.split('/');
                url.splice(0, 1);
                _this.navigateToGetHugoSection = url.join('-') + "-get-hugo-section";
                _this.isVisible.getHugo = e.url.includes('co2') ? false : true;
                _this.storage.get("authToken" /* AUTH_TOKEN */).then(function (token) {
                    _this.isLoggedIn = token ? true : false;
                    for (var _i = 0, _a = _this.menus; _i < _a.length; _i++) {
                        var menu = _a[_i];
                        if (menu.label.toLowerCase() === 'offset co2') {
                            menu.isVisible = _this.isLoggedIn ? false : true;
                            // menu.isVisible = e.url.includes('co2') ? false : true;
                        }
                    }
                });
            }
        });
    };
    HeaderComponent.prototype.ngOnChanges = function () {
        this.checkConsentStatus();
        this.cdr.detectChanges();
    };
    HeaderComponent.prototype.toggleMobileMenu = function () {
        this.isVisible.mobileMenu = !this.isVisible.mobileMenu;
        // to close mobile menu if it is open
        var subMenuTriggers = document.querySelectorAll('.mobile-nav .submenu-trigger');
        if (subMenuTriggers && subMenuTriggers.length) {
            subMenuTriggers.forEach(function (el) {
                if (el.nextElementSibling) {
                    el.nextElementSibling.classList.remove('show');
                }
            });
        }
    };
    HeaderComponent.prototype.closeMobileMenu = function () {
        this.isVisible.mobileMenu = false;
    };
    HeaderComponent.prototype.toggleMobileSubMenu = function (e) {
        var subMenuTriggers = document.querySelectorAll('.mobile-nav .submenu-trigger');
        var nextElSibClass = e.target.nextElementSibling.classList;
        if (nextElSibClass.contains('show')) {
            nextElSibClass.remove('show');
        }
        else {
            if (subMenuTriggers && subMenuTriggers.length) {
                subMenuTriggers.forEach(function (el) {
                    if (el.nextElementSibling) {
                        el.nextElementSibling.classList.remove('show');
                    }
                });
                nextElSibClass.add('show');
            }
        }
    };
    HeaderComponent.prototype.navigateToGetHugo = function (url, section) {
        window.location.hash = section;
    };
    HeaderComponent.prototype.checkConsentStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contract;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("contract" /* CONTRACT */)];
                    case 1:
                        contract = _a.sent();
                        this.dataService.setConsentStatus(contract === 'false' ? false : true);
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.goBack = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tabPage;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._defaultBack) return [3 /*break*/, 1];
                        this.navCtrl.navigateBack([this._defaultBack.toString()]);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.storage.get("tabPage" /* TAB_PAGE */)];
                    case 2:
                        tabPage = _a.sent();
                        if (tabPage) {
                            this.navCtrl.navigateBack(["/dashboard/" + tabPage]);
                        }
                        else {
                            this.navCtrl.back();
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return HeaderComponent;
}());
export { HeaderComponent };
