// https://ionicframework.com/docs/api/toast

import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';

export type ToastColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'light'
  | 'medium'
  | 'dark';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  /**
   * Present toast alert.
   * @param message - Toastr message.
   * @param color - Toastr type, default is success.
   */
  async presentToast(message: string, color: ToastColor = 'success') {
    const toast = await this.toastController.create({
      message,
      duration: 3500,
      color,
      // color: color ? color : 'success',
    });
    toast.present();
  }

  // async presentToastWithOptions() {
  //   const toast = await this.toastController.create({
  //     header: 'Toast header',
  //     message: 'Click to Close',
  //     position: 'top',
  //     buttons: [
  //       {
  //         side: 'start',
  //         icon: 'star',
  //         text: 'Favorite',
  //         handler: () => {},
  //       },
  //       {
  //         text: 'Done',
  //         role: 'cancel',
  //         handler: () => {},
  //       },
  //     ],
  //   });
  //   toast.present();
  // }
}
