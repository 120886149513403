import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RestService } from '@shared/services/rest.service';

import { ApiEndpoints } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class AutomaticTariffService {
  constructor(private restService: RestService) {}

  getAutomaticTarrif(meterId: number): Observable<any> {
    return this.restService.get(`${ApiEndpoints.automaticTariff(meterId)}`);
  }

  changeAutomaticTarrif(meterId: number, req: any): Observable<any> {
    return this.restService.put(
      `${ApiEndpoints.automaticTariff(meterId)}`,
      req
    );
  }
}
