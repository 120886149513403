import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AlertController, NavController, Platform, ActionSheetController, PopoverController, ToastController, ModalController, PickerController, } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { mergeMap, map, filter } from 'rxjs/operators';
import { ApiService } from '@providers/api.service';
import { 
// StorageService,
RedirectionService, } from '@shared/services';
import { DEFAULT_CURRENCY_USER } from './config';
import { environment } from '@environments/environment';
var AppComponent = /** @class */ (function () {
    // showSplash = true;
    function AppComponent(platform, router, titleService, activeRoute, apiProvider, navCtrl, alertController, modalCtrl, actionSheetController, popoverController, toastController, pickerController, storage, 
    // private storageService: StorageService,
    redirectionService // private dataService: DataService, // private geoPluginService: GeoPluginService
    ) {
        var _this = this;
        this.platform = platform;
        this.router = router;
        this.titleService = titleService;
        this.activeRoute = activeRoute;
        this.apiProvider = apiProvider;
        this.navCtrl = navCtrl;
        this.alertController = alertController;
        this.modalCtrl = modalCtrl;
        this.actionSheetController = actionSheetController;
        this.popoverController = popoverController;
        this.toastController = toastController;
        this.pickerController = pickerController;
        this.storage = storage;
        this.redirectionService = redirectionService;
        this.isDeepLink = false;
        this.networkStatus = false;
        this.networkStatus$ = Subscription.EMPTY;
        this.showNoNetworkContent = false;
        this.networkStatus = navigator.onLine;
        this.networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
            .pipe(map(function () { return navigator.onLine; }))
            .subscribe(function (status) {
            _this.networkStatus = status;
            if (_this.networkStatus === false) {
                _this.showNoNetworkContent = true;
            }
            else {
                // We just got a connection but we need to wait briefly
                // before we determine the connection type. Might need to wait.
                // prior to doing any api requests as well.
                setTimeout(function () {
                    _this.showNoNetworkContent = false;
                }, 3000);
            }
        });
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, defaultCurrencyUser, uid, address;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.storage.get("household_id" /* HOUSEHOLD_ID */)];
                    case 1:
                        _a.household = _b.sent();
                        // setting default tabpage to usage for fixing unable to go to root page from profile inner screens
                        this.storage.set("tabPage" /* TAB_PAGE */, 'dashboard/account-overview');
                        defaultCurrencyUser = localStorage.getItem('defaultCurrencyUser');
                        if (!defaultCurrencyUser) {
                            localStorage.setItem('defaultCurrencyUser', DEFAULT_CURRENCY_USER);
                        }
                        this.initializeApp();
                        return [4 /*yield*/, this.storage.get("uids" /* UID */)];
                    case 2:
                        uid = _b.sent();
                        return [4 /*yield*/, this.storage.get("address" /* ADDRESS */)];
                    case 3:
                        address = _b.sent();
                        if (uid && address != 'false') {
                            this.apiProvider.getUserDetails().then(function (uD) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var _a, meter_number, consent_status, verification_step, email_verified, is_signup_with_utility_bill, pageName;
                                return tslib_1.__generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = uD.meter, meter_number = _a.meter_number, consent_status = _a.consent_status, verification_step = uD.verification_step, email_verified = uD.email_verified, is_signup_with_utility_bill = uD.is_signup_with_utility_bill;
                                            if (!(consent_status === false)) return [3 /*break*/, 1];
                                            if (verification_step == 4) {
                                                if (is_signup_with_utility_bill) {
                                                    this.router.navigate(['/signup/steps/verify-utility-bill'], {
                                                        replaceUrl: true,
                                                        queryParams: {
                                                            screen: 'holding',
                                                        },
                                                    });
                                                }
                                                else {
                                                    this.router.navigate(['/signup/steps/add-meter'], {
                                                        replaceUrl: true,
                                                    });
                                                }
                                            }
                                            else if (verification_step == null) {
                                                if (email_verified) {
                                                    this.router.navigate(['/signup/steps'], {
                                                        replaceUrl: true,
                                                    });
                                                }
                                                else {
                                                    this.router.navigate(['/verify-email'], {
                                                        replaceUrl: true,
                                                    });
                                                }
                                            }
                                            else if (verification_step <= 3) {
                                                this.redirectTo(verification_step, is_signup_with_utility_bill);
                                            }
                                            else {
                                                // passing 5 for redirecting to usage
                                                this.redirectTo(5);
                                            }
                                            return [3 /*break*/, 3];
                                        case 1:
                                            if (!!this.isDeepLink) return [3 /*break*/, 3];
                                            return [4 /*yield*/, this.storage.get("redirectTo" /* REDIRECT_TO */)];
                                        case 2:
                                            pageName = _b.sent();
                                            // const queryParams = this.storage.set(StorageKeys.QUERY_PARAMS);
                                            if (pageName) {
                                                this.storage.remove("redirectTo" /* REDIRECT_TO */);
                                                this.storage.remove("notificationId" /* NOTIFICATION_ID */);
                                                // this.storage.remove(StorageKeys.QUERY_PARAMS);
                                                this.redirectionService.redirectTo(pageName, '');
                                            }
                                            else {
                                                this.router
                                                    .navigate(['/dashboard/account-overview'], {
                                                    replaceUrl: true,
                                                })
                                                    .then(function () { });
                                            }
                                            _b.label = 3;
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); });
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
    }
    AppComponent.prototype.ionViewWillLeave = function () {
        this.disconnectSubscription.unsubscribe();
        this.connectSubscription.unsubscribe();
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.networkStatus$) {
            this.networkStatus$.unsubscribe();
        }
    };
    AppComponent.prototype.redirectTo = function (step, isSignupWithUtilityBill) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var verificationMethod, isMpanInvalid;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("verification_method" /* VERIFICATION_METHOD */)];
                    case 1:
                        verificationMethod = _a.sent();
                        return [4 /*yield*/, this.storage.get("isMpanInvalid" /* IS_MPAN_INVALID */)];
                    case 2:
                        isMpanInvalid = _a.sent();
                        switch (step) {
                            case 1:
                                this.router.navigate(['/signup/steps/add-meter']);
                                break;
                            case 2:
                                // this.router.navigate(['/signup/steps/terms-and-conditions']);
                                this.router.navigate([
                                    // verificationMethod == 'IHD'
                                    isMpanInvalid === true || isMpanInvalid === 'true'
                                        ? '/signup/steps/unable-to-find'
                                        : '/signup/steps/terms-and-conditions',
                                ]);
                                break;
                            case 3:
                                this.router.navigate([
                                    isSignupWithUtilityBill
                                        ? '/signup/steps/verify-utility-bill'
                                        : '/signup/steps/confirm-your-right',
                                ], tslib_1.__assign({}, (isSignupWithUtilityBill && {
                                    queryParams: {
                                        screen: 'holding',
                                    },
                                })));
                                break;
                            default:
                                this.router
                                    .navigate(['/dashboard/account-overview'], { replaceUrl: true })
                                    .then(function () { });
                                break;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.initializeApp = function () {
        this.platform.ready().then(function () {
            window.addEventListener('load', function () {
                (function (w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async = true;
                    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                    f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', environment.gtm.id);
                (function (id) {
                    var s = document.createElement('script');
                    s.async = true;
                    s.src = 'https://www.googletagmanager.com/gtag/js?id=' + id;
                    document.head.prepend(s);
                })(environment.gtag.id);
            });
            // setTimeout(() => (this.showSplash = false), 2500);
            // this.geoPluginService.geoLocation().subscribe({
            //   next: (res) => {
            //     this.dataService.setGeoLocationData(res);
            //   },
            //   error: (err) => {},
            // });
        });
    };
    AppComponent.prototype.presentMaintenanceAlert = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            cssClass: 'cst-alert-dialog cst-alert-dialog--app-maintenance',
                            header: data.title,
                            message: data.message,
                            backdropDismiss: false,
                            mode: 'ios',
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.ngOnInit = function () {
        this.subscribeToRouteChangeEvents();
    };
    AppComponent.prototype.setTitleFromRouteData = function (routeData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, showHeader, showBackButton, defaultBack;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("authToken" /* AUTH_TOKEN */)];
                    case 1:
                        token = _a.sent();
                        if (routeData) {
                            showHeader = routeData.showHeader, showBackButton = routeData.showBackButton, defaultBack = routeData.defaultBack;
                            this.showHeader = showHeader !== undefined ? showHeader : false;
                            this.showBackButton =
                                showBackButton !== undefined
                                    ? token
                                        ? showBackButton
                                        : this.router.url.includes('select-location') ||
                                            this.router.url.includes('co2/message')
                                            ? false
                                            : showBackButton
                                    : false;
                            this.defaultBack = defaultBack !== undefined ? defaultBack : false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.getLatestChild = function (route) {
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    };
    AppComponent.prototype.subscribeToRouteChangeEvents = function () {
        var _this = this;
        // Set initial title
        var latestRoute = this.getLatestChild(this.activeRoute);
        if (latestRoute) {
            this.setTitleFromRouteData(latestRoute.data.getValue());
        }
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.activeRoute; }), map(function (route) { return _this.getLatestChild(route); }), filter(function (route) { return route.outlet === 'primary'; }), mergeMap(function (route) { return route.data; }))
            .subscribe(function (event) {
            // get and set default currency
            var defaultCurrencyUser = localStorage.getItem('defaultCurrencyUser');
            if (!defaultCurrencyUser) {
                localStorage.setItem('defaultCurrencyUser', DEFAULT_CURRENCY_USER);
            }
            else {
                localStorage.setItem('defaultCurrencyUser', defaultCurrencyUser);
            }
            _this.setTitleFromRouteData(event);
        });
    };
    return AppComponent;
}());
export { AppComponent };
