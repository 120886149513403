import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-meter',
  templateUrl: './meter.component.html',
  styleUrls: ['./meter.component.scss'],
})
export class MeterComponent implements OnInit, AfterContentChecked {
  @Input() pan: string;
  constructor(public modalController: ModalController) {}
  close() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }
  ngOnInit() {}

  ngAfterContentChecked() {
    const el = document.getElementById('mpan-modal');
    // el.parentElement.parentElement.parentElement.style.setProperty(
    //   '--height',
    //   `${el.offsetHeight}px`
    // );
    el.parentElement.parentElement.parentElement.setAttribute(
      'style',
      `--height: ${el.offsetHeight}px !important; z-index: 20000 !important;`
    );
  }
}
