import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
var LoaderService = /** @class */ (function () {
    function LoaderService(loadingController) {
        this.loadingController = loadingController;
        this.isLoading = false;
        this.loader = null;
    }
    LoaderService.prototype.present = function (message, spinner) {
        if (message === void 0) { message = ''; }
        if (spinner === void 0) { spinner = 'bubbles'; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this.loadingController
                                .create({
                                // duration: 5000,
                                message: message,
                                spinner: spinner,
                            })
                                .then(function (load) {
                                _this.loader = load;
                                _this.loader.present().then(function () {
                                    if (!_this.isLoading) {
                                        _this.loader.dismiss().then(function () { });
                                    }
                                });
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LoaderService.prototype.dismiss = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = false;
                        return [4 /*yield*/, this.loadingController.dismiss().then(function () { })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LoaderService.prototype.appLoading = function (e, msg, spinner) {
        e ? this.present(msg, spinner) : this.dismiss();
    };
    LoaderService.prototype.updateLoaderMessage = function (msg) {
        if (this.loader && this.loader.message) {
            this.loader.message = msg;
        }
    };
    LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(i0.ɵɵinject(i1.LoadingController)); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };
