import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestService } from './rest.service';
import { ToastService } from './toast.service';
import { FileExtentions } from '../models/enums';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  setHeaders(options?: any) {
    const headers = {
      'Content-Type': 'application/binary',
    };
    const newHeaders = Object.assign(headers, options);
    return { headers: newHeaders };
  }

  constructor(
    private restService: RestService,
    private toastService: ToastService
  ) {}

  uploadFileOnSignedUrl(url: string, file: any): Observable<any> {
    return this.restService.put(`${url}`, file, {
      ...this.setHeaders({ 'Content-Type': file.type }),
      reportProgress: true,
      observe: 'events',
    });
  }

  // Utils
  getExtension(fileName: string): string {
    const parts = fileName.split('.');
    return parts[parts.length - 1].toLowerCase();
  }

  validateFileExtension(
    fileName: string,
    supportedFileExtensions?: string[],
    toastMessage?: string
  ): boolean {
    const extensions =
      supportedFileExtensions && supportedFileExtensions.length
        ? supportedFileExtensions
        : [
            FileExtentions.JPEG,
            FileExtentions.JPG,
            FileExtentions.PNG,
            FileExtentions.PDF,
          ];
    if (fileName && !extensions.includes(this.getExtension(fileName))) {
      this.toastService.presentToast(
        toastMessage ||
          `File type not supported please select .${extensions.join(', .')}`,
        'danger'
      );
      return false;
    }
    return true;
  }
}
