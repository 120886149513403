import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { ExchangeRate } from '../models/interfaces';
import { GeoLocation } from '../models/interfaces/output';

export enum DataKeys {
  OFFSETTING_DETAILS = 'offsettingDetails',
  PLANTING_DETAILS = 'plantingDetails',
  PROJECT_DETAILS = 'projectDetails',
  LOCATION_DETAILS = 'locationDetails',
}

interface Data {
  // address?: string;
  // uids?: number;
  // signupStep?: number;
  // household_id?: number;
  // uobj?: string;
  // mpan?: number;
  // meterid_electricity?: number;
  // authToken?: string;
  // email?: string;
  // postCode?: string;
  // contract?: boolean;
  // email_verified?: boolean;
  // phone?: string;
  // tabPage?: string;
  // isMpanInvalid?: boolean;
  // co2Usage?: number;
  [DataKeys.OFFSETTING_DETAILS]?: string;
  [DataKeys.PLANTING_DETAILS]?: string;
  [DataKeys.PROJECT_DETAILS]?: string;
  [DataKeys.LOCATION_DETAILS]?: string;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  notificationCount = new BehaviorSubject(0);
  notificationQueryParams = new BehaviorSubject(null);
  deeplinkQueryParams = new BehaviorSubject(null);
  consentStatus = new BehaviorSubject(false);
  userDetails = new BehaviorSubject(null);
  consumptionBy = new BehaviorSubject(false);
  storageData = new BehaviorSubject(null);
  geoLocationData = new BehaviorSubject<GeoLocation | null>(null);
  currencyExchangeRates = new BehaviorSubject<ExchangeRate | null>(null);

  constructor() {}

  getNotificationCount() {
    return this.notificationCount.asObservable();
  }
  setNotificationCount(count: number) {
    this.notificationCount.next(count);
  }

  getNotificationQueryParams() {
    return this.notificationQueryParams.asObservable();
  }
  setNotificationQueryParams(qp: any) {
    this.notificationQueryParams.next(qp);
  }

  getDeeplinkQueryParams() {
    return this.deeplinkQueryParams.asObservable();
  }
  setDeeplinkQueryParams(qp: any) {
    this.deeplinkQueryParams.next(qp);
  }

  getConsentStatus() {
    return this.consentStatus.asObservable();
  }
  setConsentStatus(status: boolean) {
    this.consentStatus.next(status);
  }

  getUserDetails() {
    return this.userDetails.asObservable();
  }
  setUserDetails(data: any) {
    this.userDetails.next(data);
  }

  getConsumptionBy() {
    return this.consumptionBy.asObservable();
  }
  setConsumptionBy(value: boolean) {
    this.consumptionBy.next(value);
  }

  getStorageData() {
    return this.storageData.asObservable();
  }
  setStorageData(data: Data) {
    this.storageData.next(Object.assign(this.storageData, data));
  }

  getGeoLocationData() {
    return this.geoLocationData.asObservable();
  }
  setGeoLocationData(data: GeoLocation) {
    return this.geoLocationData.next(data);
  }

  getCurrencyExchangeRates() {
    return this.currencyExchangeRates.asObservable();
  }
  setCurrencyExchangeRates(currencyExchangeRates: ExchangeRate) {
    this.currencyExchangeRates.next(currencyExchangeRates);
  }
}
