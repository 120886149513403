import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';

import { Storage } from '@ionic/storage';

import { StorageKeys } from '@shared/models/enums';
import { DataService } from '@shared/services';
import { AuthService } from '@providers/auth.service';

import { APP_STORE_URL, PLAY_STORE_URL } from '@app/config';
import { environment } from '@environments/environment';

const DOMAIN = environment.websiteUrl;

interface Menu {
  label: string;
  url?: string;
  type: 'web-target' | 'web-link' | 'app-link' | 'dropdown';
  isVisible: boolean;
  subMenus?: Menu[];
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges {
  private _showHeader = false;
  private _showBackButton = false;
  private _defaultBack = false;

  consentStatus = false;

  url: string;
  navigateToGetHugoSection: string;
  menus: Menu[] = [
    {
      label: 'Features',
      url: `${DOMAIN}/#features`,
      type: 'web-target',
      isVisible: true,
    },
    {
      label: 'Media',
      type: 'dropdown',
      isVisible: true,
      subMenus: [
        {
          label: 'Press',
          url: `${DOMAIN}/topics/press/`,
          type: 'web-link',
          isVisible: true,
        },
        {
          label: 'Blog',
          url: `${DOMAIN}/topics/blog/`,
          type: 'web-link',
          isVisible: true,
        },
        {
          label: 'Videos',
          url: `${DOMAIN}/topics/meet-hugo/`,
          type: 'web-link',
          isVisible: true,
        },
      ],
    },
    {
      label: 'Ask Hugo',
      type: 'dropdown',
      isVisible: true,
      subMenus: [
        {
          label: 'Virtual Assistant',
          url: `${DOMAIN}`,
          type: 'web-link',
          isVisible: true,
        },
        {
          label: 'FAQs',
          url: `${DOMAIN}/faqs/`,
          type: 'web-link',
          isVisible: true,
        },
        {
          label: 'Contact Us',
          url: `${DOMAIN}/contact-us/`,
          type: 'web-link',
          isVisible: true,
        },
      ],
    },
    {
      label: 'Offset Co2',
      url: `/dashboard/co2/select-location`,
      type: 'app-link',
      isVisible: true,
    },
  ];
  domain = `${DOMAIN}/`;

  isLoggedIn = false;
  isVisible = {
    getHugo: true,
    mobileMenu: false,
  };
  appStoreUrl = APP_STORE_URL;
  playStoreUrl = PLAY_STORE_URL;

  constructor(
    public router: Router,
    public navCtrl: NavController,
    private cdr: ChangeDetectorRef,

    private storage: Storage,

    private dataService: DataService,
    public authService: AuthService
  ) {}

  @Input()
  set showHeader(showHeader: boolean) {
    this._showHeader = showHeader;
  }
  get showHeader(): boolean {
    return this._showHeader;
  }

  @Input()
  set showBackButton(showBackButton: boolean) {
    this._showBackButton = showBackButton;
  }
  get showBackButton(): boolean {
    return this._showBackButton;
  }

  @Input()
  set defaultBack(value: boolean) {
    this._defaultBack = value;
  }
  get defaultback(): boolean {
    return this._defaultBack;
  }

  ngOnInit() {
    this.dataService.getConsentStatus().subscribe((status: boolean) => {
      this.consentStatus = status;
    });

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.url = e.url;
        let url: any = e.url;
        if (url.indexOf('#') >= 0) {
          url = url.split('#')[0];
        }
        url = url.split('/');
        url.splice(0, 1);

        this.navigateToGetHugoSection = `${url.join('-')}-get-hugo-section`;

        this.isVisible.getHugo = e.url.includes('co2') ? false : true;

        this.storage.get(StorageKeys.AUTH_TOKEN).then((token) => {
          this.isLoggedIn = token ? true : false;

          for (const menu of this.menus) {
            if (menu.label.toLowerCase() === 'offset co2') {
              menu.isVisible = this.isLoggedIn ? false : true;
              // menu.isVisible = e.url.includes('co2') ? false : true;
            }
          }
        });
      }
    });
  }

  ngOnChanges() {
    this.checkConsentStatus();
    this.cdr.detectChanges();
  }

  toggleMobileMenu(): void {
    this.isVisible.mobileMenu = !this.isVisible.mobileMenu;

    // to close mobile menu if it is open
    const subMenuTriggers = document.querySelectorAll(
      '.mobile-nav .submenu-trigger'
    );
    if (subMenuTriggers && subMenuTriggers.length) {
      subMenuTriggers.forEach((el: HTMLElement) => {
        if (el.nextElementSibling) {
          el.nextElementSibling.classList.remove('show');
        }
      });
    }
  }

  closeMobileMenu(): void {
    this.isVisible.mobileMenu = false;
  }

  toggleMobileSubMenu(e: any): void {
    const subMenuTriggers = document.querySelectorAll(
      '.mobile-nav .submenu-trigger'
    );
    const nextElSibClass = e.target.nextElementSibling.classList;
    if (nextElSibClass.contains('show')) {
      nextElSibClass.remove('show');
    } else {
      if (subMenuTriggers && subMenuTriggers.length) {
        subMenuTriggers.forEach((el: HTMLElement) => {
          if (el.nextElementSibling) {
            el.nextElementSibling.classList.remove('show');
          }
        });
        nextElSibClass.add('show');
      }
    }
  }

  navigateToGetHugo(url: string, section: string): void {
    window.location.hash = section;
  }

  async checkConsentStatus() {
    const contract = await this.storage.get(StorageKeys.CONTRACT);
    this.dataService.setConsentStatus(contract === 'false' ? false : true);
  }

  async goBack() {
    if (this._defaultBack) {
      this.navCtrl.navigateBack([this._defaultBack.toString()]);
    } else {
      const tabPage = await this.storage.get(StorageKeys.TAB_PAGE);

      if (tabPage) {
        this.navCtrl.navigateBack([`/dashboard/${tabPage}`]);
      } else {
        this.navCtrl.back();
      }
    }
  }
}
