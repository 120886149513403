import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// import {
//   SecureStorage,
//   SecureStorageObject,
// } from '@ionic-native/secure-storage/ngx';
import { Storage } from '@ionic/storage';
import { ApiService } from './api.service';
import { CancelHttpCallsService } from '@providers/cancel-http-calls.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/storage";
import * as i3 from "@angular/router";
import * as i4 from "./api.service";
import * as i5 from "./cancel-http-calls.service";
var AuthService = /** @class */ (function () {
    function AuthService(http, 
    // public secureStorage: SecureStorage,
    storage, router, apiProvider, cancelHttpCallsService) {
        this.http = http;
        this.storage = storage;
        this.router = router;
        this.apiProvider = apiProvider;
        this.cancelHttpCallsService = cancelHttpCallsService;
    }
    AuthService.prototype.setUid = function (uid) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // sets user ID in local storage
            _this.storage.set("uids" /* UID */, uid.uid);
            _this.storage.set("uobj" /* UOBJ */, JSON.stringify(uid));
            // this.storage.set('auth-token', uid.access_token);
            resolve('done');
        });
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        this.logoutWithoutRedirect();
        setTimeout(function () { return _this.router.navigate(['/home'], { replaceUrl: true }); }, 500);
    };
    AuthService.prototype.logoutWithoutRedirect = function () {
        this.signOut();
        this.clearStorage();
    };
    AuthService.prototype.signOut = function () {
        var _this = this;
        this.apiProvider
            .signOut()
            .then(function (res) {
            _this.apiProvider.createToast('Successfully Logout');
            _this.cancelHttpCallsService.cancelPendingRequests();
        })
            .catch(function (err) { });
    };
    AuthService.prototype.clearStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var Email, TabPage, UserDefaultCurrency;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("email" /* EMAIL */)];
                    case 1:
                        Email = _a.sent();
                        return [4 /*yield*/, this.storage.get("tabPage" /* TAB_PAGE */)];
                    case 2:
                        TabPage = _a.sent();
                        UserDefaultCurrency = localStorage.getItem('defaultCurrencyUser');
                        return [4 /*yield*/, this.storage.clear()];
                    case 3:
                        _a.sent();
                        if (Email) {
                            this.storage.set("email" /* EMAIL */, Email);
                        }
                        this.storage.set("tabPage" /* TAB_PAGE */, TabPage);
                        localStorage.setItem('defaultCurrencyUser', UserDefaultCurrency);
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.ApiService), i0.ɵɵinject(i5.CancelHttpCallsService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
