import { Component, OnInit } from '@angular/core';

import { ExternalLinks } from '@app/config';

@Component({
  selector: 'app-website-footer',
  templateUrl: './website-footer.component.html',
  styleUrls: ['./website-footer.component.scss'],
})
export class WebsiteFooterComponent implements OnInit {
  externalLinks = ExternalLinks;
  year = new Date().getFullYear();
  constructor() {}

  ngOnInit() {}
}
