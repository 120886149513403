import { Directive, ElementRef } from '@angular/core';
import supportsWebP from 'supports-webp';

@Directive({
  selector: '[appSupportsWebp]',
})
export class SupportsWebpDirective {
  constructor(private el: ElementRef) {
    setTimeout(async () => {
      this.el.nativeElement.classList.add(
        (await supportsWebP) ? 'webp' : 'no-webp'
      );
    }, 0);
  }
}
