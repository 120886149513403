/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./components/header/header.component.ngfactory";
import * as i5 from "./components/header/header.component";
import * as i6 from "@angular/router";
import * as i7 from "@ionic/storage";
import * as i8 from "./shared/services/data.service";
import * as i9 from "../providers/auth.service";
import * as i10 from "@angular/common";
import * as i11 from "./components/no-connection/no-connection.component.ngfactory";
import * as i12 from "./components/no-connection/no-connection.component";
import * as i13 from "./app.component";
import * as i14 from "@angular/platform-browser";
import * as i15 from "../providers/api.service";
import * as i16 from "./shared/services/redirection.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-app", [], null, null, null, i2.View_IonApp_0, i2.RenderType_IonApp)), i1.ɵdid(1, 49152, null, 0, i3.IonApp, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-header", [], null, null, null, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i1.ɵdid(3, 638976, null, 0, i5.HeaderComponent, [i6.Router, i3.NavController, i1.ChangeDetectorRef, i7.Storage, i8.DataService, i9.AuthService], { showHeader: [0, "showHeader"], showBackButton: [1, "showBackButton"], defaultBack: [2, "defaultBack"] }, null), (_l()(), i1.ɵeld(4, 16777216, null, 0, 1, "ion-router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i3.IonRouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], [8, null], i3.Config, i3.NavController, i10.Location, i1.ElementRef, i6.Router, i1.NgZone, i6.ActivatedRoute, [3, i3.IonRouterOutlet]], { swipeGesture: [0, "swipeGesture"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "app-no-connection", [], [[2, "d-none", null]], null, null, i11.View_NoConnectionComponent_0, i11.RenderType_NoConnectionComponent)), i1.ɵdid(7, 114688, null, 0, i12.NoConnectionComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showNoNetworkContent; var currVal_1 = _co.showBackButton; var currVal_2 = _co.defaultBack; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = false; _ck(_v, 5, 0, currVal_3); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = !_co.showNoNetworkContent; _ck(_v, 6, 0, currVal_4); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i13.AppComponent, [i3.Platform, i6.Router, i14.Title, i6.ActivatedRoute, i15.ApiService, i3.NavController, i3.AlertController, i3.ModalController, i3.ActionSheetController, i3.PopoverController, i3.ToastController, i3.PickerController, i7.Storage, i16.RedirectionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
