import * as i0 from "@angular/core";
var StorageService = /** @class */ (function () {
    function StorageService() {
    }
    StorageService.prototype.getAuthoken = function () {
        var _this = this;
        return new Promise(function (res) {
            var t = _this.getItem("authToken" /* AUTH_TOKEN */);
            t ? res(t) : res('');
        });
    };
    StorageService.prototype.setAuthoken = function (authToken) {
        this.setItem("authToken" /* AUTH_TOKEN */, authToken);
    };
    StorageService.prototype.getUID = function () {
        var _this = this;
        return new Promise(function (res, rej) {
            var t = _this.getItem("uids" /* UID */);
            t ? res(t) : rej({});
        });
    };
    StorageService.prototype.setUID = function (uid) {
        this.setItem("uids" /* UID */, uid);
    };
    StorageService.prototype.getMeterId = function () {
        return this.getItem("meterid_electricity" /* METER_ID */);
    };
    StorageService.prototype.setMeterId = function (meterId) {
        this.setItem("meterid_electricity" /* METER_ID */, meterId);
    };
    StorageService.prototype.getMPAN = function () {
        return this.getItem("mpan" /* MPAN */);
    };
    StorageService.prototype.setMPAN = function (mpan) {
        this.setItem("mpan" /* MPAN */, mpan);
    };
    StorageService.prototype.getSessionId = function () {
        return this.getItem("sessionId" /* SESSION_ID */);
    };
    StorageService.prototype.setSessionId = function (sessionId) {
        this.setItem("sessionId" /* SESSION_ID */, sessionId);
    };
    StorageService.prototype.getSignupStep = function () {
        return this.getItem("signupStep" /* SIGNUP_STEP */);
    };
    StorageService.prototype.setSignupStep = function (signupStep) {
        this.setItem("signupStep" /* SIGNUP_STEP */, "" + signupStep);
    };
    StorageService.prototype.getItem = function (key) {
        return localStorage.getItem(key);
    };
    StorageService.prototype.setItem = function (key, value) {
        localStorage.setItem(key, value);
    };
    StorageService.prototype.removeItem = function (key) {
        localStorage.removeItem(key);
    };
    StorageService.prototype.clear = function () {
        localStorage.clear();
    };
    StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
