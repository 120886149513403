import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export var DataKeys;
(function (DataKeys) {
    DataKeys["OFFSETTING_DETAILS"] = "offsettingDetails";
    DataKeys["PLANTING_DETAILS"] = "plantingDetails";
    DataKeys["PROJECT_DETAILS"] = "projectDetails";
    DataKeys["LOCATION_DETAILS"] = "locationDetails";
})(DataKeys || (DataKeys = {}));
var DataService = /** @class */ (function () {
    function DataService() {
        this.notificationCount = new BehaviorSubject(0);
        this.notificationQueryParams = new BehaviorSubject(null);
        this.deeplinkQueryParams = new BehaviorSubject(null);
        this.consentStatus = new BehaviorSubject(false);
        this.userDetails = new BehaviorSubject(null);
        this.consumptionBy = new BehaviorSubject(false);
        this.storageData = new BehaviorSubject(null);
        this.geoLocationData = new BehaviorSubject(null);
        this.currencyExchangeRates = new BehaviorSubject(null);
    }
    DataService.prototype.getNotificationCount = function () {
        return this.notificationCount.asObservable();
    };
    DataService.prototype.setNotificationCount = function (count) {
        this.notificationCount.next(count);
    };
    DataService.prototype.getNotificationQueryParams = function () {
        return this.notificationQueryParams.asObservable();
    };
    DataService.prototype.setNotificationQueryParams = function (qp) {
        this.notificationQueryParams.next(qp);
    };
    DataService.prototype.getDeeplinkQueryParams = function () {
        return this.deeplinkQueryParams.asObservable();
    };
    DataService.prototype.setDeeplinkQueryParams = function (qp) {
        this.deeplinkQueryParams.next(qp);
    };
    DataService.prototype.getConsentStatus = function () {
        return this.consentStatus.asObservable();
    };
    DataService.prototype.setConsentStatus = function (status) {
        this.consentStatus.next(status);
    };
    DataService.prototype.getUserDetails = function () {
        return this.userDetails.asObservable();
    };
    DataService.prototype.setUserDetails = function (data) {
        this.userDetails.next(data);
    };
    DataService.prototype.getConsumptionBy = function () {
        return this.consumptionBy.asObservable();
    };
    DataService.prototype.setConsumptionBy = function (value) {
        this.consumptionBy.next(value);
    };
    DataService.prototype.getStorageData = function () {
        return this.storageData.asObservable();
    };
    DataService.prototype.setStorageData = function (data) {
        this.storageData.next(Object.assign(this.storageData, data));
    };
    DataService.prototype.getGeoLocationData = function () {
        return this.geoLocationData.asObservable();
    };
    DataService.prototype.setGeoLocationData = function (data) {
        return this.geoLocationData.next(data);
    };
    DataService.prototype.getCurrencyExchangeRates = function () {
        return this.currencyExchangeRates.asObservable();
    };
    DataService.prototype.setCurrencyExchangeRates = function (currencyExchangeRates) {
        this.currencyExchangeRates.next(currencyExchangeRates);
    };
    DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
