import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var CancelHttpCallsService = /** @class */ (function () {
    function CancelHttpCallsService() {
        this.cancelPendingRequests$ = new Subject();
    }
    /* Cancels all pending Http requests. */
    CancelHttpCallsService.prototype.cancelPendingRequests = function () {
        // This aborts all HTTP requests.
        this.cancelPendingRequests$.next();
        // This completes the subject properlly.
        // this.cancelPendingRequests$.complete();
    };
    CancelHttpCallsService.prototype.onCancelPendingRequests = function () {
        return this.cancelPendingRequests$.asObservable();
    };
    CancelHttpCallsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CancelHttpCallsService_Factory() { return new CancelHttpCallsService(); }, token: CancelHttpCallsService, providedIn: "root" });
    return CancelHttpCallsService;
}());
export { CancelHttpCallsService };
