<div
  class="inner-header"
  *ngIf="showHeader"
  [class.h-100]="isVisible.mobileMenu"
>
  <div class="desktop-menu">
    <div class="cover-header-box">
      <div class="inner-header__left">
        <!-- Back button -->
        <ion-button
          fill="clear"
          *ngIf="showBackButton"
          (click)="goBack()"
          class="inner-header__back-button no-ripple"
          slot="start"
        >
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.375 5.4H1.907L6.68 1.035a.583.583 0 0 0 .021-.849.644.644 0 0 0-.883-.02L.366 5.15a1.17 1.17 0 0 0-.366.85c0 .32.13.621.377.858l5.442 4.975c.121.111.276.166.431.166.165 0 .33-.062.453-.186a.583.583 0 0 0-.022-.848L1.887 6.6h12.488A.613.613 0 0 0 15 6c0-.331-.28-.6-.625-.6z"
              fill="#2E1835"
            />
          </svg>
        </ion-button>

        <!-- Logo -->
        <div class="inner-header__logo">
          <a [href]="domain" class="d-block" aria-label="hugo logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="120"
              height="30"
              fill="none"
              viewBox="0 0 120 30"
              aria-hidden="true"
            >
              <path
                fill="#FFBF00"
                fill-rule="evenodd"
                d="M0 3.425C0 1.65 1.394.248 3.157.248c1.763 0 3.157 1.403 3.157 3.177v8.496h11.644V3.425c0-1.774 1.395-3.177 3.158-3.177 1.762 0 3.157 1.403 3.157 3.177V26.44c0 1.774-1.395 3.176-3.157 3.176-1.763 0-3.158-1.402-3.158-3.176v-8.662H6.314v8.662c0 1.774-1.394 3.176-3.157 3.176C1.394 29.616 0 28.214 0 26.44V3.425ZM29.273 16.995V3.425c0-1.774 1.394-3.177 3.157-3.177 1.763 0 3.158 1.403 3.158 3.177v13.404c0 4.703 2.336 7.136 6.191 7.136 3.854 0 6.19-2.35 6.19-6.929V3.425c0-1.774 1.395-3.177 3.158-3.177s3.157 1.403 3.157 3.177v13.363c0 8.744-4.88 13.035-12.587 13.035-7.71 0-12.424-4.332-12.424-12.828M58.014 15.014v-.082C58.014 6.724 64.368 0 73.06 0c4.346 0 7.216.99 9.8 2.806.614.453 1.311 1.319 1.311 2.557a3.124 3.124 0 0 1-3.115 3.135c-.903 0-1.477-.33-1.928-.66-1.722-1.238-3.567-1.98-6.273-1.98-4.593 0-8.242 4.083-8.242 8.992v.082c0 5.28 3.608 9.157 8.693 9.157 2.296 0 4.345-.578 5.945-1.733v-4.125h-4.265c-1.517 0-2.746-1.195-2.746-2.722a2.754 2.754 0 0 1 2.746-2.763h7.217c1.763 0 3.157 1.402 3.157 3.176v7.013c0 1.856-.697 3.134-2.214 4.04-2.337 1.444-5.74 2.889-10.045 2.889-8.939 0-15.088-6.311-15.088-14.85M112.625 15.014v-.082c0-4.95-3.608-9.074-8.692-9.074-5.085 0-8.612 4.042-8.612 8.992v.082c0 4.95 3.61 9.074 8.693 9.074 5.085 0 8.611-4.042 8.611-8.992Zm-23.904 0v-.082C88.72 6.724 95.159 0 104.014 0c8.857 0 15.212 6.642 15.212 14.85v.082c0 8.208-6.438 14.932-15.293 14.932-8.857 0-15.212-6.64-15.212-14.85Z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </div>
      </div>

      <!-- Menu -->
      <nav class="inner-header__menu" aria-label="Desktop menu">
        <ng-container *ngFor="let menuItem of menus">
          <a
            *ngIf="
              ['web-link', 'web-target'].includes(menuItem.type) &&
              menuItem.isVisible
            "
            [href]="menuItem.url"
            >{{ menuItem.label }}</a
          >
          <a
            *ngIf="menuItem.type === 'app-link' && menuItem.isVisible"
            [routerLink]="[menuItem.url]"
            routerLinkActive="is-active"
            >{{ menuItem.label }}</a
          >
          <a
            *ngIf="menuItem.type === 'dropdown' && menuItem.isVisible"
            routerLinkActive="is-active"
            class="submenu-trigger"
          >
            {{ menuItem.label }}
            <span appSvg name="angle-down" class="submenu-trigger__icon"></span>
            <ng-container
              [ngTemplateOutlet]="menu"
              [ngTemplateOutletContext]="{ $implicit: menuItem.subMenus }"
            >
            </ng-container>
          </a>
        </ng-container>
      </nav>

      <!-- get Hugo -->
      <div class="inner-header__right">
        <button
          *ngIf="isVisible.getHugo"
          (click)="navigateToGetHugo(url, navigateToGetHugoSection)"
          class="get-hugo m-0"
          aria-label="get hugo"
          type="button"
        >
          <picture>
            <source srcset="assets/img/qr-code-black.webp" type="image/webp" />
            <img
              src="assets/img/qr-code-black.jpg"
              alt="qr code image"
              width="34"
              height="34"
            />
          </picture>
          <span>GET HUGO</span>
        </button>

        <button
          class="logout"
          (click)="authService.logout()"
          *ngIf="isLoggedIn"
        >
          <span>Log out</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="34"
            fill="none"
            viewBox="0 0 32 34"
          >
            <path
              fill="#FFBF00"
              fill-rule="evenodd"
              d="M8.726 26.132c5.42-.822 9.15-.752 14.577.029.393.059.752.264 1.01.577.26.313.4.712.398 1.124 0 .395-.13.779-.367 1.078a49.93 49.93 0 0 1-1.263 1.54h2.09c.132-.163.264-.33.398-.498a3.43 3.43 0 0 0 .725-2.119c0-1.665-1.17-3.1-2.774-3.33-5.555-.8-9.437-.876-15.022-.028-1.622.246-2.787 1.704-2.787 3.38 0 .745.233 1.48.676 2.077.13.176.26.349.388.518h2.04a47.266 47.266 0 0 1-1.173-1.521 1.808 1.808 0 0 1-.348-1.074c0-.886.613-1.629 1.432-1.753ZM16.001 18.143c.624 0 1.242-.127 1.818-.375a4.742 4.742 0 0 0 1.541-1.07 4.952 4.952 0 0 0 1.03-1.6 5.102 5.102 0 0 0 0-3.778 4.95 4.95 0 0 0-1.03-1.6 4.741 4.741 0 0 0-1.541-1.07 4.596 4.596 0 0 0-1.818-.376c-1.26 0-2.468.52-3.359 1.446a5.033 5.033 0 0 0-1.39 3.489c0 1.308.5 2.564 1.39 3.489a4.662 4.662 0 0 0 3.36 1.445Zm0 1.645c1.68 0 3.29-.693 4.479-1.927a6.711 6.711 0 0 0 1.855-4.652 6.711 6.711 0 0 0-1.855-4.653A6.215 6.215 0 0 0 16 6.63c-1.68 0-3.29.694-4.478 1.927a6.711 6.711 0 0 0-1.855 4.653c0 1.745.667 3.418 1.855 4.652a6.215 6.215 0 0 0 4.478 1.927Z"
              clip-rule="evenodd"
            />
            <path
              fill="#FFBF00"
              fill-rule="evenodd"
              d="M16.001 32.124c7.87 0 14.25-6.628 14.25-14.803 0-8.176-6.38-14.804-14.25-14.804S1.751 9.145 1.751 17.321c0 8.175 6.38 14.803 14.25 14.803Zm0 1.645c8.745 0 15.834-7.364 15.834-16.448C31.835 8.236 24.746.872 16 .872 7.257.872.168 8.236.168 17.321c0 9.084 7.089 16.448 15.833 16.448Z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <a
          class="singup-link"
          routerLink="/signup"
          *ngIf="
            !isLoggedIn &&
            url !== '/signup' &&
            url !== '/signup#signup-get-hugo-section'
          "
          >Sign Up</a
        >
      </div>
    </div>
  </div>

  <div class="mobile-menu" [class.is-open]="isVisible.mobileMenu">
    <div class="mobile-menu__header">
      <!-- Back button -->
      <ion-button
        fill="clear"
        *ngIf="showBackButton"
        (click)="goBack()"
        class="inner-header__back-button no-ripple"
        slot="start"
      >
        <svg
          width="15"
          height="12"
          viewBox="0 0 15 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.375 5.4H1.907L6.68 1.035a.583.583 0 0 0 .021-.849.644.644 0 0 0-.883-.02L.366 5.15a1.17 1.17 0 0 0-.366.85c0 .32.13.621.377.858l5.442 4.975c.121.111.276.166.431.166.165 0 .33-.062.453-.186a.583.583 0 0 0-.022-.848L1.887 6.6h12.488A.613.613 0 0 0 15 6c0-.331-.28-.6-.625-.6z"
            fill="#2E1835"
          />
        </svg>
      </ion-button>

      <!-- Logo -->
      <div class="logo my-0 mx-auto">
        <a [href]="domain" class="d-block" aria-label="hugo logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="30"
            fill="none"
            viewBox="0 0 120 30"
            class="d-block"
            aria-hidden="true"
          >
            <path
              [attr.fill]="isVisible.mobileMenu ? '#2E1835' : '#FFBF00'"
              fill-rule="evenodd"
              d="M0 3.425C0 1.65 1.394.248 3.157.248c1.763 0 3.157 1.403 3.157 3.177v8.496h11.644V3.425c0-1.774 1.395-3.177 3.158-3.177 1.762 0 3.157 1.403 3.157 3.177V26.44c0 1.774-1.395 3.176-3.157 3.176-1.763 0-3.158-1.402-3.158-3.176v-8.662H6.314v8.662c0 1.774-1.394 3.176-3.157 3.176C1.394 29.616 0 28.214 0 26.44V3.425ZM29.273 16.995V3.425c0-1.774 1.394-3.177 3.157-3.177 1.763 0 3.158 1.403 3.158 3.177v13.404c0 4.703 2.336 7.136 6.191 7.136 3.854 0 6.19-2.35 6.19-6.929V3.425c0-1.774 1.395-3.177 3.158-3.177s3.157 1.403 3.157 3.177v13.363c0 8.744-4.88 13.035-12.587 13.035-7.71 0-12.424-4.332-12.424-12.828M58.014 15.014v-.082C58.014 6.724 64.368 0 73.06 0c4.346 0 7.216.99 9.8 2.806.614.453 1.311 1.319 1.311 2.557a3.124 3.124 0 0 1-3.115 3.135c-.903 0-1.477-.33-1.928-.66-1.722-1.238-3.567-1.98-6.273-1.98-4.593 0-8.242 4.083-8.242 8.992v.082c0 5.28 3.608 9.157 8.693 9.157 2.296 0 4.345-.578 5.945-1.733v-4.125h-4.265c-1.517 0-2.746-1.195-2.746-2.722a2.754 2.754 0 0 1 2.746-2.763h7.217c1.763 0 3.157 1.402 3.157 3.176v7.013c0 1.856-.697 3.134-2.214 4.04-2.337 1.444-5.74 2.889-10.045 2.889-8.939 0-15.088-6.311-15.088-14.85M112.625 15.014v-.082c0-4.95-3.608-9.074-8.692-9.074-5.085 0-8.612 4.042-8.612 8.992v.082c0 4.95 3.61 9.074 8.693 9.074 5.085 0 8.611-4.042 8.611-8.992Zm-23.904 0v-.082C88.72 6.724 95.159 0 104.014 0c8.857 0 15.212 6.642 15.212 14.85v.082c0 8.208-6.438 14.932-15.293 14.932-8.857 0-15.212-6.64-15.212-14.85Z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
      </div>

      <ion-button
        (click)="toggleMobileMenu()"
        fill="clear"
        class="menu-handle m-0"
      >
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="isVisible.mobileMenu"
          class="d-block"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.71406 8L14.6638 12.9497C15.0543 13.3403 15.0543 13.9734 14.6638 14.364L14.1924 14.8354C13.8019 15.2259 13.1687 15.2259 12.7782 14.8354L7.82844 9.88562L2.87869 14.8354C2.48817 15.2259 1.855 15.2259 1.46448 14.8354L0.993074 14.364C0.60255 13.9734 0.602549 13.3403 0.993073 12.9497L5.94282 8L0.993073 3.05025C0.602549 2.65973 0.602549 2.02656 0.993073 1.63604L1.46448 1.16463C1.855 0.774111 2.48817 0.77411 2.87869 1.16463L7.82844 6.11438L12.7782 1.16463C13.1687 0.77411 13.8019 0.77411 14.1924 1.16463L14.6638 1.63604C15.0543 2.02656 15.0543 2.65973 14.6638 3.05025L9.71406 8Z"
            fill="#2E1835"
          />
        </svg>

        <svg
          width="22"
          height="14"
          viewBox="0 0 22 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="d-block"
          *ngIf="!isVisible.mobileMenu"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.3333 14C20.9777 14 21.5 13.4777 21.5 12.8333C21.5 12.189 20.9777 11.6667 20.3333 11.6667H1.66667C1.02233 11.6667 0.5 12.189 0.5 12.8333C0.5 13.4777 1.02233 14 1.66667 14H20.3333ZM20.3333 8.16667C20.9777 8.16667 21.5 7.64433 21.5 7C21.5 6.35567 20.9777 5.83333 20.3333 5.83333H1.66667C1.02233 5.83333 0.5 6.35567 0.5 7C0.5 7.64433 1.02233 8.16667 1.66667 8.16667H20.3333ZM0.5 1.16667C0.5 1.811 1.02233 2.33333 1.66667 2.33333H20.3333C20.9777 2.33333 21.5 1.811 21.5 1.16667C21.5 0.522334 20.9777 0 20.3333 0H1.66667C1.02234 0 0.5 0.522334 0.5 1.16667Z"
            fill="#FFBF00"
          />
        </svg>
      </ion-button>
    </div>
    <div class="mobile-menu__body">
      <!-- Menu -->
      <nav class="mobile-nav" aria-label="Mobile menu">
        <ul class="mobile-nav-list">
          <li class="mobile-nav-list__item" *ngFor="let menuItem of menus">
            <a
              *ngIf="
                ['web-link', 'web-target'].includes(menuItem.type) &&
                menuItem.isVisible
              "
              [href]="menuItem.url"
              (click)="closeMobileMenu()"
              >{{ menuItem.label }}</a
            >
            <a
              *ngIf="menuItem.type === 'app-link' && menuItem.isVisible"
              [routerLink]="[menuItem.url]"
              routerLinkActive="is-active"
              (click)="closeMobileMenu()"
              >{{ menuItem.label }}</a
            >
            <ng-container
              *ngIf="menuItem.type === 'dropdown' && menuItem.isVisible"
            >
              <a
                routerLinkActive="is-active"
                class="submenu-trigger"
                (click)="toggleMobileSubMenu($event)"
                >{{ menuItem.label }}
                <span
                  appSvg
                  name="angle-down"
                  class="submenu-trigger__icon"
                ></span>
              </a>
              <ng-container
                [ngTemplateOutlet]="mobileMenu"
                [ngTemplateOutletContext]="{ $implicit: menuItem.subMenus }"
              >
              </ng-container>
            </ng-container>
          </li>
        </ul>
      </nav>

      <ion-button
        class="text-uppercase signup-button"
        shape="round"
        routerLink="/signup"
        (click)="closeMobileMenu()"
        *ngIf="!isLoggedIn && url !== '/signup'"
        >SIGN UP</ion-button
      >
      <ion-button
        class="text-uppercase signup-button"
        shape="round"
        (click)="closeMobileMenu(); authService.logout()"
        *ngIf="isLoggedIn"
        >LOG OUT</ion-button
      >

      <div
        class="store-btns d-flex ion-align-items-center ion-justify-content-center"
      >
        <a
          [href]="appStoreUrl"
          target="_blank"
          class="store-button store-button--app-store ion-activatable ripple-parent"
          (click)="closeMobileMenu()"
        >
          <span appSvg name="app-store" class="store-button__symbol"></span>
          <ion-ripple-effect type="unbounded"></ion-ripple-effect>
        </a>
        <a
          [href]="playStoreUrl"
          target="_blank"
          class="store-button store-button--play-store ion-activatable ripple-parent"
          (click)="closeMobileMenu()"
        >
          <span appSvg name="play-store" class="store-button__symbol"></span>
          <ion-ripple-effect type="unbounded"></ion-ripple-effect>
        </a>
      </div>
    </div>
  </div>
</div>

<!-- ./emulator -avd Pixel_2_XL_API_28 -dns-server 8.8.8.8,8.8.4.4 -->

<ng-template #menu let-menuItem>
  <ul class="submenu-list">
    <li *ngFor="let subMenu of menuItem" class="submenu-list__item">
      <a
        *ngIf="
          ['web-link', 'web-target'].includes(subMenu.type) && subMenu.isVisible
        "
        [href]="subMenu.url"
        >{{ subMenu.label }}</a
      >
      <a
        *ngIf="subMenu.type === 'app-link' && subMenu.isVisible"
        [routerLink]="[subMenu.url]"
        routerLinkActive="is-active"
        >{{ subMenu.label }}</a
      >
      <a
        *ngIf="subMenu.type === 'dropdown' && subMenu.isVisible"
        routerLinkActive="is-active"
        class="submenu-trigger"
        >{{ subMenu.label }}
        <span appSvg name="angle-down" class="submenu-trigger__icon"></span>
        <ng-container
          [ngTemplateOutlet]="menu"
          [ngTemplateOutletContext]="{ $implicit: subMenu.subMenus }"
        >
        </ng-container>
      </a>
    </li>
  </ul>
</ng-template>

<ng-template #mobileMenu let-menuItem>
  <ul class="submenu-list">
    <li *ngFor="let subMenu of menuItem" class="submenu-list__item">
      <a
        *ngIf="
          ['web-link', 'web-target'].includes(subMenu.type) && subMenu.isVisible
        "
        [href]="subMenu.url"
        (click)="closeMobileMenu()"
        >{{ subMenu.label }}</a
      >
      <a
        *ngIf="subMenu.type === 'app-link' && subMenu.isVisible"
        [routerLink]="[subMenu.url]"
        routerLinkActive="is-active"
        (click)="closeMobileMenu()"
        >{{ subMenu.label }}</a
      >
      <ng-container *ngIf="subMenu.type === 'dropdown' && subMenu.isVisible">
        <a
          routerLinkActive="is-active"
          class="submenu-trigger"
          (click)="toggleMobileSubMenu($event)"
          >{{ subMenu.label }}
          <span appSvg name="angle-down" class="submenu-trigger__icon"></span>
        </a>
        <ng-container
          [ngTemplateOutlet]="mobileMenu"
          [ngTemplateOutletContext]="{ $implicit: subMenu.subMenus }"
        >
        </ng-container>
      </ng-container>
    </li>
  </ul>
</ng-template>
