import * as tslib_1 from "tslib";
import { Router, } from '@angular/router';
// import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ionic/storage";
var AddMeterGuard = /** @class */ (function () {
    function AddMeterGuard(router, storage) {
        this.router = router;
        this.storage = storage;
    }
    AddMeterGuard.prototype.canActivate = function (next, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contract;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get("contract" /* CONTRACT */)];
                    case 1:
                        contract = _a.sent();
                        if (contract === 'true' || contract === true) {
                            this.router.navigate(['/dashboard/account-overview']);
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    AddMeterGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AddMeterGuard_Factory() { return new AddMeterGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Storage)); }, token: AddMeterGuard, providedIn: "root" });
    return AddMeterGuard;
}());
export { AddMeterGuard };
