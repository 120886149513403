import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { Subscription } from 'rxjs';

import { DEFAULT_CURRENCY_PAYMENT } from '@app/config';
import { DataService } from '../services';
import { ExchangeRate } from '../models/interfaces';

@Pipe({
  name: 'userDefaultCurrency',
})
export class UserDefaultCurrencyPipe
  extends CurrencyPipe
  implements PipeTransform, OnDestroy
{
  currencyExchangeRates$: Subscription;
  rates: ExchangeRate;
  constructor(private dataService: DataService) {
    super('en-US');
    this.currencyExchangeRates$ = this.dataService
      .getCurrencyExchangeRates()
      .subscribe((rates) => {
        this.rates = rates;
      });
  }

  transform(value: number, currencyCode?: string): any {
    const USER_DEFAULT_CURRENCY = localStorage.getItem('defaultCurrencyUser');
    if (!this.rates || (this.rates && !this.rates[USER_DEFAULT_CURRENCY])) {
      return value;
    }
    const rate = parseFloat(
      (
        this.rates[USER_DEFAULT_CURRENCY] / this.rates[DEFAULT_CURRENCY_PAYMENT]
      ).toFixed(2)
    );
    return super.transform(
      value ? value * rate : 0,
      currencyCode
        ? currencyCode.toUpperCase()
        : USER_DEFAULT_CURRENCY.toUpperCase(),
      'symbol'
    );
  }

  ngOnDestroy(): void {
    if (this.currencyExchangeRates$) {
      this.currencyExchangeRates$.unsubscribe();
    }
  }
}
