import { Injectable } from '@angular/core';

import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

interface Spinner {
  type:
    | 'bubbles'
    | 'circles'
    | 'circular'
    | 'crescent'
    | 'dots'
    | 'lines'
    | 'lines-small';
}

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading = false;

  loader: HTMLIonLoadingElement = null;

  constructor(public loadingController: LoadingController) {}

  async present(message = '', spinner: Spinner['type'] = 'bubbles') {
    this.isLoading = true;
    return await this.loadingController
      .create({
        // duration: 5000,
        message,
        spinner,
      })
      .then((load: HTMLIonLoadingElement) => {
        this.loader = load;
        this.loader.present().then(() => {
          if (!this.isLoading) {
            this.loader.dismiss().then(() => {});
          }
        });
      });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => {});
  }

  appLoading(e: boolean, msg?: string, spinner?: Spinner['type']) {
    e ? this.present(msg, spinner) : this.dismiss();
  }

  updateLoaderMessage(msg: string) {
    if (this.loader && this.loader.message) {
      this.loader.message = msg;
    }
  }
}
