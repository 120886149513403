import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
// enum PageNames {
//   BUDGET = 'Budget',
// }
var PageNames = {
    BUDGET: 'Budget',
};
var RedirectionService = /** @class */ (function () {
    function RedirectionService(router) {
        this.router = router;
    }
    RedirectionService.prototype.redirectTo = function (pageName, queryParams) {
        switch (pageName) {
            case PageNames.BUDGET:
                this.navigateTo('/dashboard/budget', queryParams);
                break;
            default:
                this.navigateTo('/dashboard/account-overview', queryParams);
        }
    };
    RedirectionService.prototype.navigateTo = function (path, queryParams) {
        queryParams
            ? this.router.navigate([path], { queryParams: queryParams })
            : this.router.navigate([path]);
    };
    RedirectionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RedirectionService_Factory() { return new RedirectionService(i0.ɵɵinject(i1.Router)); }, token: RedirectionService, providedIn: "root" });
    return RedirectionService;
}());
export { RedirectionService };
