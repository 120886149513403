import { Injectable } from '@angular/core';

// const AUTH_TOKEN = 'authToken';
// const SESSION_ID = 'sessionId';
// const METER_ID = 'meterid_electricity';
// const MPAN = 'mpan';

import { StorageKeys } from '../models/enums';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  getAuthoken(): Promise<string> {
    return new Promise((res) => {
      const t = this.getItem(StorageKeys.AUTH_TOKEN);
      t ? res(t) : res('');
    });
  }
  setAuthoken(authToken: string): void {
    this.setItem(StorageKeys.AUTH_TOKEN, authToken);
  }

  getUID(): Promise<string> {
    return new Promise((res, rej) => {
      const t = this.getItem(StorageKeys.UID);
      t ? res(t) : rej({});
    });
  }
  setUID(uid: string): void {
    this.setItem(StorageKeys.UID, uid);
  }

  getMeterId(): string {
    return this.getItem(StorageKeys.METER_ID);
  }
  setMeterId(meterId: string): void {
    this.setItem(StorageKeys.METER_ID, meterId);
  }

  getMPAN(): string {
    return this.getItem(StorageKeys.MPAN);
  }
  setMPAN(mpan: string): void {
    this.setItem(StorageKeys.MPAN, mpan);
  }

  getSessionId(): string {
    return this.getItem(StorageKeys.SESSION_ID);
  }
  setSessionId(sessionId: string): void {
    this.setItem(StorageKeys.SESSION_ID, sessionId);
  }

  getSignupStep(): string {
    return this.getItem(StorageKeys.SIGNUP_STEP);
  }
  setSignupStep(signupStep: number) {
    this.setItem(StorageKeys.SIGNUP_STEP, `${signupStep}`);
  }

  getItem(key: string): string {
    return localStorage.getItem(key);
  }
  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
  clear(): void {
    localStorage.clear();
  }
}
