import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RestService = /** @class */ (function () {
    function RestService(http) {
        this.http = http;
    }
    RestService.prototype.setHeaders = function (headers) {
        var defaultHeaders = {
            'Content-Type': 'application/json',
        };
        var newHeaders = Object.assign(defaultHeaders, headers);
        var httpOptions = {
            headers: new HttpHeaders(newHeaders),
        };
        return httpOptions;
    };
    // private setParams(params: any) {
    //   const defaultParams = {};
    //   const newParams = Object.assign(defaultParams, params);
    //   const httpParams = {
    //     params: new HttpParams(newParams),
    //   };
    //   return httpParams;
    // }
    RestService.prototype.setOptions = function (options) {
        var defaultOptions = tslib_1.__assign({}, this.setHeaders(options && options.headers), (options && options.params && { params: options.params }), (options && options.reportProgress && { reportProgress: true }), (options && options.observe && { observe: options.observe }), (options &&
            options.responseType && { responseType: options.responseType }), (options &&
            options.withCredentials && {
            withCredentials: options.withCredentials,
        }));
        return defaultOptions;
    };
    // GET
    RestService.prototype.get = function (apiURL, options) {
        return this.http
            .get(apiURL, tslib_1.__assign({}, this.setOptions(options)))
            .pipe(catchError(this.errorHandl));
    };
    // POST
    RestService.prototype.post = function (apiURL, req, options) {
        return this.http
            .post(apiURL, req, tslib_1.__assign({}, this.setOptions(options)))
            .pipe(catchError(this.errorHandl));
    };
    // PUT
    RestService.prototype.put = function (apiURL, req, options) {
        return this.http
            .put(apiURL, req, tslib_1.__assign({}, this.setOptions(options)))
            .pipe(catchError(this.errorHandl));
    };
    // PATCH
    RestService.prototype.patch = function (apiURL, req, options) {
        return this.http
            .patch(apiURL, req, tslib_1.__assign({}, this.setOptions(options)))
            .pipe(catchError(this.errorHandl));
    };
    // DELETE
    RestService.prototype.delete = function (apiURL, options) {
        return this.http
            .delete(apiURL, tslib_1.__assign({}, this.setOptions(options)))
            .pipe(catchError(this.errorHandl));
    };
    // Error handling
    RestService.prototype.errorHandl = function (error) {
        var errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        }
        else {
            // Get server-side error
            errorMessage = error;
        }
        return throwError(errorMessage);
    };
    RestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestService_Factory() { return new RestService(i0.ɵɵinject(i1.HttpClient)); }, token: RestService, providedIn: "root" });
    return RestService;
}());
export { RestService };
